//react-hooks
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//styles
import Stylesheet from "./style.module.scss";

//redux-slice
import { getNavDetails } from "../../../../../../feature/shift-slice";

//components
import Sybutton from "../../../../../../../../shared/components/sy-button/sy-button";
import SyIcons from "../../../../../../../../shared/components/sy-icons";
import SyLoadingIcon from "../../../../../../../../shared/components/sy-loading-icon";

//types
import { NavProps } from "./dashboard-nav-modal"

const DashboardNav = (props: NavProps) => {
  
  const {
    onClick,
    scyLoading,
    cgyLoading,
  } = props;
     
  const {category: {list: cgyList, selected: cgySelected}, subCategory: {list: scyList, selected: scySelected}} = useSelector(getNavDetails);
  const {t} = useTranslation();

  const onClickScyAdd = () => {
    onClick('addScy');
  };

  const onClickCgy = (val: any) => {
    onClick('selectCgy', val);
  };

  const onClickScy = (val: any) => {
    onClick('selectScy', val);
  };

  const onClickCgyAdd = () => {
    onClick('addCgy');
  };

  return (
    <div className="bg-white rounded">
      <div className="d-flex align-items-center">
        <span className={`${Stylesheet.chooseCat} p-3`}>{`${t('choose_shift_group_shift')}:`}</span>
        <div className={"d-flex  sy_hidden_scroll_x"}>
          {cgyList?.map((cgyData: any, i: any) => (
            <div key={i} className="unor me-2">
              <Sybutton
                key={cgyData.cgy_id}
                className={`${
                  cgySelected?.cgy_id === cgyData.cgy_id
                    ? `${Stylesheet.navButton} underlineAnimation`
                    : Stylesheet.navButtonHover
                } pointer `}
                onBtnClick={onClickCgy}
                label={cgyData.cgy_name}
                value={cgyData}
                type={"button"}
                size={"sm"}
              />
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {cgyLoading ? (
            <div className="pb-1">
              <SyLoadingIcon />
            </div>
          ) : (
            <SyIcons
              name={"add_circle_outline"}
              className={`pointer mx-2 ${Stylesheet.addIcon}`}
              iconType="icons"
              onClick={onClickCgyAdd}
            />
          )}
        </div>
      </div>
      <div className={"d-flex align-items-center rounded"}>
       {cgyList.length !== 0 && <p className={`${Stylesheet.chooseCatGrp} p-3`}>{`${t('choose_shift_plan_shift')}:`}</p>}
        <div className="d-flex justify-content-start align-items-center sy_hidden_scroll_x">
          {scyList?.map((scyData: any, i: any) => (
            <div key={i} className="unor me-2">
              <Sybutton
                label={scyData.scy_name}
                type="button"
                size="sm"
                className={`${
                  scySelected?.scy_id === scyData.scy_id
                    ? `${Stylesheet.navButton} underlineAnimation`
                    : Stylesheet.navButtonHover
                } pointer `}
                onBtnClick={onClickScy}
                value={scyData}
              />
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {scyLoading ? (
            <SyLoadingIcon />
          ) : cgyList.length !== 0 ? (
            <SyIcons
              name={"add_circle_outline"}
              className={`${Stylesheet.addIcon} pointer px-2 `}
              iconType="icons"
              onClick={onClickScyAdd}
            />
          ) : (
            void 0
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardNav;
