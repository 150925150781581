import { SyIcons } from "../../../../../../shared/components";
import { THEME } from "../../../../../../shared/constants/theme";
import {getDateLocaleString, getWeekRange} from "../../../../../../shared/utils/dayjs";

export const getShiftParamPayload = (value: any) => {
  const payload: any = {};
  const parts = value.split("/");
  parts.forEach((part: { split: (arg0: string) => [any, any] }) => {
    const [key, val] = part.split("=");
    if (key && val) {
      payload[key] = val;
    } else if (part) {
      payload["cgy"] = part;
    }
  });
  const url = `${payload ? `cgy=${payload?.cgy}` : ""}${
    payload?.scy ? `/scy=${payload?.scy}` : ""
  }`;
  const obj = { url: url, payload: payload };
  return obj;

  // return `${payload ? `cgy=${payload?.cgy}`:''}${payload?.sg ? `/sg=${payload?.sg}` : '' }`;
  //   return payload;
};

export const getParamValue = (searchParams: any) => {
  return searchParams.get("cgy") && /scy/.test(searchParams.get("cgy"))
    ? getShiftParamPayload(searchParams.get("cgy")).payload
    : { cgy: searchParams.get("cgy") };
  // sg: searchParams.get("sg") ? searchParams.get("sg") : null,
};

/**returns weeks first day (monday) or months first day acc to scheduler view */
export const getPayloadDate = (val: any, schedulerView: any) => {
  if (schedulerView !== 2) {
    const { start } = getWeekRange(val);
    val = start;
  } else {
    val = new Date(val.getFullYear(), val.getMonth(), 1);
  }

  return getDateLocaleString(val, "year") ?? null;
};

/**
 * get payload for shift list api
 * 
 * @param date          date     scheduler date
 * @param view          number   scheduler view month | week
 * @param cgyId         number   category id
 * @param scyId         number   group id
 * @returns             object   payload for shift list api
 */
export const getShiftPayload = (date: Date | string, view: number, cgyId?: number, scyId?: number, includeType?: boolean, shiftView: string = '') => {
  return {
    category_id: cgyId ?? null,
    sub_category_id: scyId ?? null,
    date: getPayloadDate(date, view),
    type: view == 2 ? "month" : "week",
    sm_type: includeType ? 2 : void 0,
    group_by: shiftView ? shiftView : void 0,
  };
};

/**
 * get nearby category of provided id's category
 *
 * @param cgyList   category list
 * @param id        category id
 * @returns previous category of provided id or next one if its the first category
 */
export const getNearbyNavItem = (cgyList: Array<any>, id: any, key: string = 'cgy_id') => {
  let index = cgyList.findIndex((val: any) => val?.[key] == id);
  index = index === 0 ? (index + 1) : (index - 1);
  return index >= 0 && cgyList[index];
}

export const menuIcon = <SyIcons name="more_vert"/>

export const updatedData = (events: any[] = [], highlight: Array<number>|undefined = []) => {
  if (highlight?.length === 0) {
    return events;
  }
  // If there are highlights, map through events and update colors
  return events.map((event) => {
    const updateEvnts = event.members.find(({ id }: any) => highlight?.includes(Number(id)));
    if (!updateEvnts && event.members.length) {
      return { ...event, color: THEME.colors.grey_500 };
    }
    // If no match found, return the event as is
    return event;
  });
};