export const wildcard = {
  includeCommunity: false,
  filter: [{ type: "module", filter_type: "include", mod_ids: [2] }],
};

export const shedulerButton = (expand: boolean) => [
  {
    value: expand ? 'back' : "expand",
    label: expand ? 'back_shift' : "expand_shift",
    disable: false,
  },
];
