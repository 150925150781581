export const wildcard = {
  includeCommunity: false,
  filter: [{ type: "module", filter_type: "include", mod_ids: [2] }],
};

export const status = [
  {
    value: 1,
    label: "active",
  },
  {
    value: 2,
    label: "inactive",
  },
];

export const getHeaderButtons = (disable: boolean) => [
  {
    value: 'add',
    label: "add_new_shift_shift",
    disable: disable,
  }
];

export const NAV_MENU_CONFIG = [
  {
    action: "edit",
    label: "edit_shift_shift",
    icon: "edit",
    iconType: "symbols",
  },
  {
    action: "delete",
    label: "delete_shift",
    icon: "delete",
    iconType: "symbols",
  },
];


export const EVENT_VIEW_CONFIG = {
  options: [
    { id: 1, name: "member_shift", value: "user" },
    { id: 2, name: "shift_plan_shift", value: "plan" },
  ],
  defaultValue: { id: 1, name: "member_shift" ,value: "user"},
  label: 'group_by_shift'
}
