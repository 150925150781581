export const pageSizeOptions = [100, 150, 200];


export  const fileFormat = {
    fileFormats: ['pdf', 'doc', 'docx', 'ppt', 'csv', 'txt', 'xlsx', 'xls'],
    imageFormats: ['jpg', 'jpeg', 'png', 'webp', 'svg', 'gif', 'heic' ,'jfif'],
    videoFormats: ['webm', 'mp4', 'mpeg', 'mpeg4', 'h264', 'mov', 'avi', 'wmv', 'flv', 'mkv'],
    audioFormats: ['mp3', 'ogv', 'aac' , 'flac', 'wav', 'm4a', 'ogg'],
  }; 



export const type_id_icon:any = {
  101: 'sentiment_satisfied',
  102: 'apartment',
  103: 'emoji_emotions',
  104: 'business',
  105: 'contact_phone',
  3: 'drive_eta',
  2: 'meeting_room',
  4: 'devices',
  99: 'person',
};

export const projects: any = {
  sodisys: 1,
  simpler: 2,
  shift: 3,
  auth: 4,
  webform: 5,
};
