import { useTranslation } from "react-i18next";
import { getDateLocaleString } from "../../../utils/dayjs";
import SyChip from "../../sy-chip";
import { EventCardProps } from "../sy-calendar-model";
import { memo, useCallback, useMemo } from "react";
import "../sy-calendar.scss";
import { getLightenColor, getTextColor } from "../../../utils/theming";
import Avathar from "../../../../projects/shift/modules/shift-module/components/avathar/avathar";

/**
 * This component displays event details such as the event's title, time, capacity, and members.
 * It also provides a chip for the time slot and allows clicking on the card to trigger an action.
 *
 * @param {EventCardProps} props - The props for this component.
 * @param {object} props.data - Event data, including color, start and end times, title, capacity, and members.
 * @param {boolean} [props.detailedView] - Flag to determine whether to display detailed view with avatars.
 * @param {function} [props.onClick] - Function to be called when the event card is clicked.
 * @param {any} props.value - The value passed to the `onClick` function when the event card is clicked.
 *
 */
const EventCard = (props: EventCardProps) => {
  const { data, detailedView = true, onClick, value } = props;
  const { color, start, end, title, capacity, members } = data;
  const { t } = useTranslation();

  // The style for the chip (background color lightened based on the event color)
  const chipStyle = useMemo(() => {
    return {
      backgroundColor: getLightenColor(color),
      color:getTextColor(color)
    };
  }, [color]);

  // Memoized start and end times formatted for display
  const startTime = useMemo(() => getDateLocaleString(start, "time"), [start]);
  const endTime = useMemo(() => getDateLocaleString(end, "time") === "23:59" && data?.eod ? "24:00" :getDateLocaleString(end,"time"), [end]);

  /**
   * Handles the click event for the event card.
   * Calls the `onClick` function passed in the props with the value of the event.
   */
  const handleClick = useCallback(() => {
    if (onClick) onClick(value);
  }, [value]);

  /**
   * This memoized component renders the list of avatars for the members of the event.
   */
  const AvatarList = memo(() => {
    const memoizedAvatarList = useMemo(() => {
      return members.map((data: any,index) => (
        <Avathar
        key={index}
        data={data}
        showName={true}
      />
      ));
    }, [members]);
    return (
      <div className={`calender_vertical_scroll text-center`}>
        {memoizedAvatarList}
      </div>
    );
  });

  return (
    <div
      style={chipStyle}
      className={`p-1 rounded text-center`}
      onClick={onClick}
    >
      <SyChip
        label={`${startTime}-${endTime}`}
        color={data?.color}
        size="sm"
        className={"p-1 d-flex align-items-center justify-content-center"}
      />
      <div>
        <p className={` xm-font text-center title mx-auto ellipsis`}>
          {data?.title}
        </p>
        <p className="capacity mb-0 text-center xm-font d-flex justify-content-center align-items-center">
        {`${data?.members.length} ${t("from_shift")} `}{!data?.capacity ? <span className="infinity">&#8734;</span> : data?.capacity}{" "}
        </p>
      </div>
      {detailedView && <AvatarList />}
    </div>
  );
};

export default memo(EventCard);
