import { memo, useCallback } from "react";
import "../sy-calendar.scss";
import { RenderTimeSlotProps } from "../sy-calendar-model";

/**
 * Renders a time slot for a given day, with the ability to handle a click event.
 *
 * @param {number} props.index - The index of the time slot (used for the key).
 * @param {number} props.hourHeight - The height (in pixels) of the time slot.
 * @param {number} props.time - The time to be displayed for this time slot.
 * @param {Date} props.day - The day associated with the time slot. It can be undefined.
 * @param {function} [props.onClickTime] - A callback function to handle the click event, passing `time` and `day` as arguments.
 */

const RenderTimeSlot = (props: RenderTimeSlotProps) => {
  const { index, hourHeight = 70, time, day, onClickTime = () => {} } = props;

  // Handles the click event on a time slot.
  const onTimeClick = useCallback(() => {
    onClickTime(time, day);
  }, [day, time]);

  return (
    <div
      className="time-slot"
      key={index}
      style={{ height: `${hourHeight}px` }}
      onClick={onTimeClick}
    >
      {day ? "" : <div>{time}</div>}
    </div>
  );
};

export default memo(RenderTimeSlot);
