import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Sybutton from "../../../sy-button/sy-button";
import SyDateNavigator from "../../../sy-date-navigation";
import { Dayjs } from "dayjs";
import { getDateLocaleString, getWeekByDate } from "../../../../utils/dayjs";
import { props } from "./header-sheduler-modal";
import { SHEDULER_VIEW_CONFIG } from "./config";
import Stylesheet from "./style.module.scss";

const HeaderSheduler = (props: props) => {
  const {
    headerButtons = [],
    onButtonClick,
    shedulerChange,
    viewModes = ['week', 'month'],
    customHeader,
  } = props;

  const { t } = useTranslation();
  const [date, setDate] = useState< Dayjs| Date >(new Date());

  const onChangeDate = (val: Date | Dayjs) => {
    setDate(val);
    onButtonClick(val, 'date')
  }

  const onClick = (val: any) => {
    onButtonClick(val, 'headerBtn');
  }

  const viewButtonConfig = useMemo(() => {
    return viewModes.map((val) => SHEDULER_VIEW_CONFIG[val])
  }, [viewModes]);

  return (
    <>
      <div className={`row g-0 w-100 p-2 px-3 d-flex justify-content-between flex-wrap`}>
        <div className=" d-flex ps-2 justify-content-start align-items-center col-xl-4 col-lg-4 col-md-3" >
          {customHeader? customHeader : void 0}
          {headerButtons.length
            ? headerButtons.map((button: any, index: number) => (
                <span key={index}>
                  <Sybutton
                    className={`primarybutton  ms-3`}
                    label={t(button.label)}
                    type={"button"}
                    size={"sm"}
                    value={button.value}
                    onBtnClick={onClick}
                    disabled={button.disable}
                  />
                </span>
              ))
            : void 0}
        </div>
        <div
          className={`d-flex justify-content-center align-items-center col-lg-4 col-md-5 pe-1`}
        >
          <SyDateNavigator
            mode={shedulerChange == 1 ? 'week' : shedulerChange == 2 ? 'month' : 'day'}
            onChangeDate={onChangeDate}
            customTitle={shedulerChange == 1 ? `${t("cw_shift")}-${getWeekByDate(date)}, ${getDateLocaleString(date, 'onlyYear')}` : void 0}
          />
        </div>

        <div className={`d-flex justify-content-end col-xl-4 col-lg-4 col-md-4`}>
          {viewButtonConfig.map((button: any, index: number) => (
            <span key={index}>
              <Sybutton
                className={`${shedulerChange == button.value
                  ? Stylesheet.active
                  : Stylesheet.inactive
                  } me-2  border`}
                label={t(button.label)}
                type={"button"}
                size={"sm"}
                value={button.value}
                onBtnClick={onClick}
              />
            </span>
          ))}
        </div>
      </div>
    </>
  );
};
export default memo(HeaderSheduler);
