export const dateFormatConfig: Record<string, string> = {
  date: "DD.MM.YYYY",
  month: "MM.DD.YYYY",
  year: "YYYY-MM-DD",
  dateTime: "DD-MM-YYYY HH:mm",
  dateTime12h: "DD-MM-YYYY hh:mm a",
  time: "HH:mm",
  time12h: "hh:mm a",
  timeWithSecond: "HH:mm:ss",
  fullDateTime:"DD.MM.YYYY HH:mm"
};
