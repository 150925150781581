import { useTranslation } from "react-i18next";
import Stylesheet from "../style.module.scss";
import tinycolor from "tinycolor2";
import { getDateLocaleString } from "../../../utils/dayjs";
import SyChip from "../../sy-chip";
import { memo } from "react";
import { getLightenColor, getTextColor } from "../../../utils/theming";

type props = {
  data?: any;
  highlightId?: any;
  value?: any;
  onCardClick?: (val: any, type: string) => void;
};

const EventCard = (props: props) => {
  const { data, onCardClick, value } = props;
  const { t } = useTranslation();

  const bgColor = getLightenColor(data.color, 20);

  const chipStyle = {
    backgroundColor: bgColor,
    color:getTextColor(data?.color)
  };

  const startTime = getDateLocaleString(data?.start, "time");
  const endTime = getDateLocaleString(data?.end, "time") === "23:59" && data?.eod ? "24:00" : getDateLocaleString(data?.end, "time");

  const onClick = () => {
    if (onCardClick) onCardClick(value, "event");
  };

  return (
    <div
      style={chipStyle}
      className={`p-2 rounded text-center`}
      onClick={onClick}
    >
      <SyChip
        label={`${startTime}-${endTime}`}
        color={data?.color}
        size="sm"
        className={"p-1 d-flex align-items-center justify-content-center"}
      />
      <div>
        <p className={` xm-font text-center title mx-auto ellipsis`}>
          {data?.title}
        </p>
        <p className={`${Stylesheet.capacity} mb-0 text-center xm-font d-flex justify-content-center align-items-center`}>
          {`${data?.members.length} ${t("from_shift")} `}{!Number(data?.capacity) ? <span className="infinity">&#8734;</span> : data?.capacity}{" "}
        </p>
      </div>
    </div>
  );
};

export default memo(EventCard);
