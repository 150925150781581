import { DayProps } from "./sy-calendar-model";
import { addTime, compareDate, getDateDiff, getDateLocaleString, getDateWithEndTime, getDateWithStartTime, getDayInMonth, getStartOfDay, getStartOfMonth, getStartOfWeek, isSameDay } from "../../utils/dayjs";


export const calculateCalendarWidth = (
  div1Ref: any,
  calendarView = 1 | 2,
  dates: []
) => {
  const containerWidth = div1Ref?.current?.offsetWidth;
  const daysInView = calendarView === 1 ? 7.1 : dates.length;
  return containerWidth / daysInView;
};


export const splitEventsByDay = (events: any) => {
  const splitEvents: any = [];

  events.forEach((event: any) => {
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);

    // Calculate the number of days the event spans
    const startDay: any = getDateWithStartTime(startDate);
    const endDay: any = getDateWithStartTime(endDate)

    const dayCount = Math.ceil((endDay - startDay) / (1000 * 60 * 60 * 24)) + 1;

    if (dayCount <= 1) {
      // Event does not span multiple days
      splitEvents.push({
        ...event,
        continuesFromPreviousDay: false,
        continuesToNextDay: false,
      });
    } else {
      for (let i = 0; i < dayCount; i++) {
        const currentDay = new Date(
          startDay.getFullYear(),
          startDay.getMonth(),
          startDay.getDate() + i
        );

        const newEvent = { ...event };

        if (i === 0) {
          // First day of the event
          newEvent.start = event.start;
          newEvent.end = getDateWithEndTime(currentDay).toISOString();
          newEvent.continuesFromPreviousDay = false;
          newEvent.continuesToNextDay = true;
          newEvent.eod= true;
        } else if (i === dayCount - 1) {
          // Last day of the event
          newEvent.start = getDateWithStartTime(currentDay).toISOString();
          newEvent.end = event.end;
          newEvent.continuesFromPreviousDay = true;
          newEvent.continuesToNextDay = false;
        } else {
          // Intermediate days
          newEvent.start = getDateWithStartTime(currentDay).toISOString();
          newEvent.end = getDateWithEndTime(currentDay).toISOString();
          newEvent.continuesFromPreviousDay = true;
          newEvent.continuesToNextDay = true;
          newEvent.eod= true
        }

        splitEvents.push(newEvent);
      }
    }
  });

  return splitEvents;
};

export const assignEventsToDates = (
  events: [],
  dates: any,
  dayWidth: number,
  hourHeight: number,
  eventWidth: number,
  list:boolean,
) => {
  if (!events) return;
  const Dates = dates.forEach((day: any) => {
    day.events = getEventsForDate(events, day.date);
    day.width = calculateEventPositions(
      day.events,
      dayWidth,
      hourHeight,
      eventWidth,
      list
    );
  });
  return Dates;
};

export const getEventsForDate = (events: any, date: any) => {
  return events.filter((event: any) => {
    const eventStart = getDateLocaleString(event.start, "dateTimeHour");
    const eventEnd = getDateLocaleString(
      event.end || event.start,
      "dateTimeHour"
    );
    return isSameDay(eventStart, date) || isSameDay(eventEnd, date);
  });
};

const initializeEventProperties = (events: any[]) => {
  events.forEach((event: any) => {
    event.conflicts = new Set();
    event.columnIndex = null;
    event.positioned = false;
  });
};

const buildConflictGraph = (events: [], isOverlapping: Function) => {
  // Iterate over each pair of events in the list
  for (let i = 0; i < events.length; i++) {
    const eventA: any = events[i];
    for (let j = i + 1; j < events.length; j++) {
      const eventB: any = events[j];
      // Check if the two events overlap using the provided 'isOverlapping' function
      if (isOverlapping(eventA, eventB)) {
        eventA.conflicts.add(eventB);
        eventB.conflicts.add(eventA);
      }
    }
  }
};

const groupEvents = (events: []) => {
  const groups: any = [];
  const visitedEvents = new Set();

  // Depth-First Search (DFS) to explore conflicts and group events together
  const dfs = (event: any, group: any) => {
    visitedEvents.add(event);
    group.push(event);
    // For each conflicting event, recursively add it to the same group
    event.conflicts.forEach((conflictEvent: any) => {
      if (!visitedEvents.has(conflictEvent)) {
        dfs(conflictEvent, group);
      }
    });
  };

  // Loop over all events and apply DFS to find all connected conflict groups
  events.forEach((event: any) => {
    if (!visitedEvents.has(event)) {
      const group: any = [];
      dfs(event, group);
      groups.push(group);
    }
  });
  return groups;
};

// Assigns columns to events in a group based on conflicts
const assignColumnsToGroup = (group: []) => {
  group.forEach((event: any) => {
    const usedColumns = new Set();
    event.conflicts.forEach((conflictEvent: any) => {
      if (conflictEvent.columnIndex !== null) {
        usedColumns.add(conflictEvent.columnIndex);
      }
    });

    // Assign the smallest available column index
    let columnIndex = 0;
    while (usedColumns.has(columnIndex)) {
      columnIndex++;
    }
    event.columnIndex = columnIndex; // Assign the available column to the event
  });
};

// Sets the style (position, size, and color) for an event
const setEventStyles = (
  event: any,
  hourHeight: number,
  eventWidth: number,
  maxDayWidth: number
) => {

  // const minutesFromStart = start.diff(dayStart, "minutes");
  const minutesFromStart = getDateDiff(getStartOfDay(event.start),event.start,'minute');
  // const durationInMinutes = end.diff(start, "minutes");
  const durationInMinutes = getDateDiff(event.start,event.end,'minute');

  // Position the event from the top based on its start time
  let top = (minutesFromStart / 60) * hourHeight;
  // Calculate the height of the event based on its duration
  const height = (durationInMinutes / 60) * hourHeight;

  top += hourHeight;

  const left = event.columnIndex * eventWidth;
  const nowdayWidth = left + eventWidth;
  maxDayWidth = maxDayWidth < nowdayWidth ? nowdayWidth : maxDayWidth;
  event.style = {
    top: `${top + 2}px`,
    height: `${height}px`,
    left: `${left}px`,
    width: `${eventWidth}px`,
    background: event.color,
  };
  return maxDayWidth;
};

export const calculateEventPositions = (
  events: [],
  dayWidth: number,
  hourHeight: number,
  eventWidth: number,
  list:boolean
) => {
  // Initialize event properties
  initializeEventProperties(events);
  // Build conflict graph by identifying overlaps
  buildConflictGraph(events, isOverlapping);

  // Group events into conflict groups
  const groups: Array<any> = groupEvents(events);
  let maxDayWidth: any = dayWidth;
  // Assign columns within each group
  groups.forEach((group: any) => {
    // Sort events by start time
    group.sort((a: any, b: any) => getDateDiff(b.start,a.start));
    // Column assignment using a greedy algorithm
    assignColumnsToGroup(group);

    // Set styles for each event in the group
    group.forEach((event: any) => {
      maxDayWidth = setEventStyles(
        event,
        hourHeight,
        eventWidth,
        maxDayWidth,
      );
    });
  });
  return list ? dayWidth : maxDayWidth;
};

export const isOverlapping = (eventA: any, eventB: any) => {
  const endA = addTime(eventA.end || eventA.start,1,"minutes")

  const endB = addTime(eventB.end || eventB.start,1, "minutes");

  // return startA.isBefore(endB) && endA.isAfter(startB);
  return compareDate(eventA.start,endB,"before") && compareDate(endA,eventB.start,'after');
};

const syncScroll = (sourceDivRef:any,targetDivRef:any,isSyncingSource:any,isSyncingTarget:any)=>{
  sourceDivRef.current.addEventListener("scroll", () => {
    if (!isSyncingSource.current) {
      isSyncingTarget.current = true;
      targetDivRef.current.scrollTop = sourceDivRef.current.scrollTop;
      targetDivRef.current.scrollLeft = sourceDivRef.current.scrollLeft;
    }
    isSyncingSource.current = false;
    // onTooltipClose();
  });
}

 // Initialize synchronized scrolling
export const initSyncScroll = (
  div1Ref: any,
  div2Ref: any,
  isSyncingDiv1Scroll: any,
  isSyncingDiv2Scroll: any,
  // onTooltipClose: Function
) => {
  if (div1Ref.current && div2Ref.current) {
  
    syncScroll(div1Ref,div2Ref,isSyncingDiv1Scroll,isSyncingDiv2Scroll)
    syncScroll(div2Ref,div1Ref,isSyncingDiv2Scroll,isSyncingDiv1Scroll)
   
  }
};

 export const isDateSelectable = (
   val: DayProps,
   isPreviousDisabled: boolean,
   isFutureDisabled: boolean
 ) => {
   const currentDate =  getDateLocaleString(new Date(),'year');
   
   const selectedDate = val?.date.format("YYYY-MM-DD");
   if (!isPreviousDisabled && currentDate >= selectedDate || !isFutureDisabled && currentDate <= selectedDate) {
     return true;
   }
   return false;
 };

 export const sortEventsByStartDate = (events: any[]) => {
  return events?.sort((a, b) => {
    const startA = new Date(a.start as string);
    const startB = new Date(b.start as string);
    return startA.getTime() - startB.getTime();
  });
};