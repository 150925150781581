import dayjs from "dayjs";
import { getDateLocaleString, getWeekRange } from "../../utils/dayjs";
import { IMode } from "./sy-date-navigator";
import { getLocale } from "../../utils/helper";

/**
 * get date text in date or week or month format of given date
 * 
 * @param date          Date
 * @param mode          string      of navigator mode `day | week | month`
 * @param customTitle   string      of view text
 * @returns formated date text or date range incase of week mode
 */
export const getDefaultText = (date: Date, mode: IMode, customTitle: any) => {
    const currentDate: any = new Date(date);
    const locale: string = getLocale();
    switch (true) {

        case !!customTitle:
            return customTitle ?? '';

        case mode === 'day':
            return getDateLocaleString(currentDate, 'date');

        case mode === 'month':
            const month = currentDate.toLocaleString(locale, { month: 'long' });
            return `${month}, ${currentDate.getFullYear()}`;

        case mode === 'week':
            const { start, end } = getWeekRange(currentDate);
            return `${getDateLocaleString(start, 'date')} - ${getDateLocaleString(end, 'date')}`
    }
}


/**
 * get incremented or decremented date/week/month from a date
 * 
 * @param date   Date       to be incremented or decremented
 * @param mode   string     of navigator mode `day | week | month`
 * @param op     string     of operation `increment | decrement`
 * @returns      changed date according to input
 */
export const getChangedNavDate = (date: Date, mode: IMode, op: 'increment' | 'decrement') => {
    date = new Date(date);
    switch (mode) {

        case 'day':
            return new Date(date.setDate(op === 'increment' ? date.getDate() + 1 : date.getDate() - 1));

        case 'week':
            return new Date(date.setDate(op === 'increment' ? date.getDate() + 7 : date.getDate() - 7));

        case 'month':
            date = new Date(date.getFullYear(), date.getMonth(), 1); //incase of date being more than 28
            return new Date(date.setMonth(op === 'increment' ? date.getMonth() + 1 : date.getMonth() - 1));
    }
}