import dayjs from "dayjs";
import { getShiftParamPayload } from "../../projects/shift/modules/shift-module/screens/dashboard-screen/helper";
import { getParamPayload } from "../../projects/simpler/modules/simpler-module/screens/dashboard-screen/helper";

export const setQueryParams = (param: any = "") => {
  let newurl = `${getCurrentLocation()}${param?.url ? "?" + param?.url : ""}`;
  window.history.pushState({ path: newurl }, "", newurl);
};

export const getCurrentLocation = (withParam = false) => {
  const searchParams = new URLSearchParams(window.location.search).toString();
  if (!withParam) {
    return window.location.origin + window.location.pathname === "/"
      ? ""
      : window.location.pathname;
  } else {
    return (
      window.location.origin +
      window.location.pathname +
      "?" +
      searchParams.toString()
    );
  }
};

export const getDashBoardParamPayload = (
  value: any,
  mine: any,
  viewControl: any,
  archiveClick: any,
  selectedTask?: any
) => {


  return `${getParamPayload(value)}&mine=${mine ? "1" : "0"}&view=${viewControl?.viewType == "grid" ? "grid" : "list"
    }&archive=${archiveClick?.data ? "1" : "0"}${selectedTask ? `&sel=${selectedTask.project}_${selectedTask.task}` : ''}`;
};

export const getShiftDashBoardParamPayload = (value: any) => {
  return getShiftParamPayload(value);
};

/** returns current locale.  eg,. en-US */
export const getLocale = () => {
  return navigator.language;
}

export const removeTags = (value: any) => {
  if (!value) {
    return null;
  }
  const result = value.replace(/<[^\/>]+>/g, '')
    .replace(/<\/(p|h[1-6]|li|ul|ol)>/g, '\n')
    .replace(/<\/[^>]+>/g, '');
  return result;
}

/** returns current locale from dayjs. eg,. en */
export const getDayjsLocale = () => {
  return dayjs.locale();
}

/**
 * returns a deep clone of provided data
 * @param     data      data to be cloned
 * @returns             cloned data
 */
export const syDeepClone = (data: any) => {
  return JSON.parse(JSON.stringify(data));
}

/**
 * to check if the object with id in provided key exist
 *
 * @param arr   array           to check   
 * @param id    string|number   id to check
 * @param key   string          key of id in object
 * @returns     boolean         true when object with id exist, false otherwise
 */
export const hasOwnObjectIn = (arr: Array<any>, id: any, key: string) => {
  return arr.length && id && (arr.findIndex((val: any) => Number(val?.[key]) === Number(id)) !== -1)
}

/**
 * to get object with id on given key
 *
 * @param arr   array           of find values
 * @param id    string|number   id to check
 * @param key   string          key of id in object
 * @returns     object          when object with id exist, false| undefined otherwise
 */
export const findItemIn = (arr: Array<any>, id: any, key: string) => {
  return arr.length && id && arr.find((val: any) => Number(val?.[key]) === Number(id))
}
