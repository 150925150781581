import { getWeekDays } from "../../../../../../shared/utils/dayjs";

export const getWeekDaysWithId = () => {
  return getWeekDays("dd").map((dayData, i) => {
    return {
      ...dayData,
      id: i === 6 ? 0 : i + 1,
    };
  });
};
