import { SyChip, SyIcons } from "../../../../../../../shared/components";
import Errormessage from "../../../../../../../shared/components/forms/errormessage/errormessage";
import Avathar from "../../../components/avathar/avathar";

type Props = {
  data: any;
  onClickDelete: (val: any) => void;
  errorMessage?: string;
  displayId?:string;
};

const MemberList = ({ data, onClickDelete, errorMessage, displayId }: Props) => {
  const handleDeleteClick = () => onClickDelete(data);
  return (
    <div className=" d-flex justify-content-between flex-wrap pb-2">
      <div className="col-5">
        <Avathar
          id={displayId}
          data={data}
          showName={true}
        />
      </div>
      {data?.actual_time > 0 && (
        <div className="col-2">
          <SyChip
            label={`${data?.alloted_time}/${
              data?.actual_time ? data?.actual_time : "0"
            }h`}
            color={"#c7eabb"}
            size="sm"
          />
        </div>
      )}
      <div className="col-1">
        <SyIcons
          name={"close"}
          className={` cursor-pointer pointer bg-white ml-5 opacity-75`}
          value={data.id}
          iconType="icons"
          onClick={handleDeleteClick}
        />
      </div>
      <div className="col-12 d-flex justify-content-end">
        {errorMessage ? <Errormessage message={errorMessage}/> : void 0}
      </div>
    </div>
  );
};

export default MemberList;