import { memo, useMemo, useState } from "react";
import SyIcons from "../sy-icons";
import styleSheet from "./style.module.scss";
import { getChangedNavDate, getDefaultText } from "./helper";
import Datepicker from "../forms/date-picker";
import { getDateLocaleString } from "../../utils/dayjs";

export type IMode = "day" | "week" | "month";
type Props = {
  mode: IMode;
  customTitle?: string;
  initialDate?: Date;
  onChangeDate: (date: any) => void;
};

/**
 * SyDateNavigator Component
 * @param {object} props - The component props
 * @param {IMode} props.mode - The mode of the navigator, can be "day", "week", or "month"
 * @param {string} [props.customTitle] - Custom title for the navigator, if provided
 * @param {Date} [props.initialDate] - Initial date for the navigator, defaults to current date if not provided
 * @param {function} props.onChangeDate - Callback function to handle date changes
 *
 * @returns {JSX.Element} The SyDateNavigator component
 */

const SyDateNavigator = (props: Props) => {
  const { mode, customTitle, initialDate, onChangeDate } = props;
  const [date, setDate] = useState<Date>(initialDate || new Date());
  const [showDatePicker, setShowDatePicker] = useState<Boolean>(false);
   
  /**
   * Views of Date Picker
   */
   const views = useMemo<("day" | "month" | "year")[]>(() => {
    if (mode === "week") {
      return ["day", "month", "year"];
    } else if (mode === "month") {
      return ["month", "year"];
    } else {
      return ["year"];
    }
  }, [mode]);
  
  /**
   * Handler for navigating to the previous date based on the current mode.
   */
  const onClickPrev = () => {
    const prevDate = getChangedNavDate(date, mode, "decrement");
    setDate(prevDate);
    onChangeDate(prevDate);
  };

  /**
   * Handler for navigating to the next date based on the current mode.
   */
  const onClickNext = () => {
    const nextDate = getChangedNavDate(date, mode, "increment");
    setDate(nextDate);
    onChangeDate(nextDate);
  };

  /**
   * Opens the date picker modal.
   */
  const openPicker = () => {
    setShowDatePicker(true);
  };

  /**
   * Handler for selecting a date from the date picker.
   * Closes the date picker after selection.
   */
  const onSelectDate = (date: any) => {
    setDate(date);
    onChangeDate(date);
    setShowDatePicker(false);
  };

  /**
   * Closes the date picker modal without selecting a date.
   */
  const closePicker = () => {
    setShowDatePicker(false);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-center">
        <div>
          <SyIcons
            name={"chevron_left"}
            iconType="icons"
            onClick={onClickPrev}
          />
        </div>
        <div
          onClick={openPicker}
          className={`px-2 ${styleSheet.text_style} d-flex justify-content-center`}
        >
          {getDefaultText(date, mode, customTitle)}
        </div>
        <div>
          <SyIcons
            name={"chevron_right"}
            iconType="icons"
            onClick={onClickNext}
          />
        </div>
        {showDatePicker && (
          <Datepicker
            open={showDatePicker}
            Placeholder=""
            views={views}
            value={date && getDateLocaleString(date, "year")} // Format the date to string
            selectedDate={onSelectDate} // Handle the selected date callback
            date={date}
            className={`${showDatePicker && styleSheet.custompicker}`}
            onClose={closePicker}
          />
        )}
      </div>
    </div>
  );
};

export default memo(SyDateNavigator);
