import { DateOrTimeView, LocalizationProvider, MobileDateTimePicker, MobileDateTimePickerSlotsComponentsProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { forwardRef } from "react";
import Errormessage from "../errormessage/errormessage";
import { getDayjsLocale } from "../../../utils/helper";
import { dateFormatConfig } from "./config";

type Props = {
  placeholder: string;
  onChange: (value: Date | Dayjs) => void;
  value?: Date | Dayjs;
  minVal?: Date | Dayjs;
  disabled?:boolean;
  format?: "date" | "month" | "year" | "dateTime" | "time" | "time12h" | "timeWithSecond" | "dateTime12h"|"fullDateTime";
  errorMessage?: any;
};

const SyDateTimepicker = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
    const {
      placeholder,
      onChange,
      value,
      errorMessage,
      minVal,
      format = "dateTime",
      disabled,
      ...rest
    } = props;

    const handleChange = (val: any) => {
      onChange(val || null);
    };

    const slotProps: MobileDateTimePickerSlotsComponentsProps<Dayjs | Date, DateOrTimeView> = {
      textField: {
        size: "small",
        error: false,
        fullWidth: true,
      },
    };

    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getDayjsLocale()}>
          <MobileDateTimePicker
            ref={ref}
            value={dayjs(value)}
            ampm={false}
            label={placeholder}
            onChange={handleChange}
            format={dateFormatConfig[format]}
            minDateTime={minVal ? dayjs(minVal) : void 0}
            slotProps={slotProps}
            disabled={disabled}
            {...rest}
          />
        </LocalizationProvider>
        {errorMessage ? (
          <span className="d-flex justify-content-end">
            <Errormessage message={errorMessage} />
          </span>
        ) : (
          void null
        )}
      </>
    );
  }
);

export default SyDateTimepicker;
