//react
import { useState, useMemo, memo, ReactElement } from "react";
import Stylesheet from "./style.module.scss";
import HeaderSheduler from "../sy-sheduler/components/header-sheduler";
import {
  generateCalendarList,
  generateDatesFrom,
  getViewBy,
} from "./helper";
import { getDateLocaleString } from "../../utils/dayjs";
import { useTranslation } from "react-i18next";
import { syDeepClone } from "../../utils/helper";
import EventSlot from "./components/event-slot";
import SlotWrapper from "./components/slot-wrapper";
import { EMPTY_LIST_CONFIG } from "./config";
import Avathar from "../../../projects/shift/modules/shift-module/components/avathar/avathar";

function SyDaywiseEventList({
  source,
  onHeaderBtnClick,
  headerButtonConfig,
  onCellClick,
  disabled = false,
  viewModes,
  customHeaderElement,
  onClickEntity
}: {
  source: any;
  onHeaderBtnClick: (val: any, type: string) => void;
  headerButtonConfig: any;
  onCellClick: (val: any, type: string) => void;
  disabled?: boolean;
  viewModes?: Array<any>;
  customHeaderElement?: ReactElement;
  onClickEntity: (val:any) => void;
}) {
  const { t } = useTranslation();
  const [view, setView] = useState("week");
  const [date, setDate] = useState(getDateLocaleString(new Date(), "year"));

  const viewDates = generateDatesFrom(date, view);
  const calendarList =  generateCalendarList(date, syDeepClone(source.length ? source : EMPTY_LIST_CONFIG), view)

  const onHeaderButtonClick = (val: any, type: any) => {
    if (type === "headerBtn") {
      if (val === 1 || val === 2 || val === 3) {
        setView(getViewBy(val));
      }
    }
    if (type === "date") {
      const date = getDateLocaleString(val, "year");
      setDate(date);
    }
    onHeaderBtnClick(val, type);
  };

  const handleClick = (value: any, type: string) => {
    if (!disabled) {
      onCellClick(value, type);
    }
  }

  const EventList = memo(({ events }: { events: Array<any> }) => {
    const eventList = useMemo(
      () =>
        events.map((event: any, index: any) => (
          <EventSlot
            key={index}
            handleClick={handleClick}
            event={event}
            value={event}
            view={view}
          />
        )),
      [view, events]
    );
    return <>{eventList}</>;
  });

  const ShiftList = memo(({ data }: { data: any }) => {
    const { shifts, entity } = data;
    const shiftList = useMemo(() =>
      shifts.map((slot: any, i: number) => (
        <td key={i} className={`text-center ${view == "week" ? "col-1" : ""}`}>
          <SlotWrapper
            onClick={handleClick}
            data={{ entity, date: slot.date }}
          >
            {slot.events.length
              ? <EventList events={slot.events} />
              : <></>}
          </SlotWrapper>
        </td>
      )), [view, data]);
    return <>{shiftList}</>;
  });

  const CalendarBody = memo(() => {
    const calendarBody = useMemo(() =>
      calendarList.map((data: any, index: number) => {
        const onEntityClick = () => {
          onClickEntity(data);
        }        
        return (
          <tr key={index}>
            {data.entity.name ? (
              <td className={`${view == "week" ? "col-1" : ""} text-center px-2 ${Stylesheet.entityName}`}>
                {data.entity.name == "unassigned_shifts_shift" || data.entity.type == 2 ? (
                  <p className={`${Stylesheet.entityName}`}>
                    {t(data.entity.name)}
                  </p>
                ) : (
                  <div onClick={onEntityClick} className="pointer">
                      <Avathar
                        data={data.entity}
                        avatarSize={"lg"}
                        showName={true}
                      />
                  </div>
                )}
              </td>
            ) : (
              " "
            )}
            <ShiftList data={data} />
          </tr>
        );}), [calendarList]);

    return <>{calendarBody}</>;
  });

  const DatesHeader = memo(()=>{
    const datesHeader = useMemo(() => viewDates.map((date, i) => (
      <td key={i} className={`text-center ${Stylesheet.tableHeadStyle}`}>
        {getDateLocaleString(date.date, view === "week" ? "date" : "setdateFormate")}
      </td>
    )), [viewDates, view]);

    return <>{datesHeader}</>;
  })

  return (
    <div className={`${Stylesheet.scheduler_container} bg-white py-1 rounded`}>
      <div className={`${Stylesheet.scheduler_header} `}>
        <HeaderSheduler
          headerButtons={headerButtonConfig}
          onButtonClick={onHeaderButtonClick}
          shedulerChange={view === "week" ? 1 : view === "month" ? 2 : 3}
          viewModes={viewModes}
          customHeader={customHeaderElement}
        />
      </div>
      <div className={`${Stylesheet.scheduler_body}`}>
        <table className="w-100">
          <thead>
            <tr>
              {(calendarList.length && calendarList[0]?.entity?.name) ? <td></td> : ''}
              <DatesHeader/>
            </tr>
          </thead>
          <tbody>
            <CalendarBody/>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default SyDaywiseEventList;