import Stylesheet from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { getDateLocaleString } from "../../../../../../../../shared/utils/dayjs";



const NotAssignedTableRow = ({
    data,
    onClick,
  }: {
    data: any;
    onClick: any;
  }) => {
    const { t } = useTranslation();

    const handleEditClick = () => {
      onClick(data.id);
    };
    
  
    return (
      <tr key={data.id} className={`${Stylesheet.notAssigned_tr}`}>
          <td>{data.id}</td>
          <td className={`${Stylesheet.title}`}>{data.title}</td>
          <td>{getDateLocaleString(data.start,"date")}</td>
          <td>{!data.capacity ? 0 : data.capacity}</td>
          <td>
              {getDateLocaleString(data.start, "time")} {t("to_shift")}{" "}
              {getDateLocaleString(data.end, "time")}
          </td>
          <td>
              <span
                  className={`pointer material-symbols-outlined me-2 align-items-center`}
                  onClick={handleEditClick}
              >
                  {"edit"}
              </span>
          </td>
      </tr>
    );
  };
  
export default NotAssignedTableRow;