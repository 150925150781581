export const getStatusLabel = (options: any) => options?.label;

export const getGroupLabel = (options: any) => options?.cgy_name;

export const getMemberLabel = (options: any) => options?.name;

export const getDataFromOptions = (id: any, data: any, key: any) => {
  return data.filter((data: any) => {
    return data[key] === id;
  })[0];
};

export const getMembers = (members: any) => {
  return members.map((data: any) => {
    return {
      // user: data,
      // alloted_time: data.alloted_time,
      u_id: data.id,
      name: data.name,
      type_id: data.type_id,
      alloted_time: data.alloted_time,
    };
  });
};

export const getUniqMemberlist = (list: Record<string, any>, fields: Record<string, any>) => {
  const ids: Array<number | string> = fields.map((val: any)=> val.u_id);
  return list.filter((val: any)=> !ids.includes(val.id)) ?? [];
}

export const deleteUidIn = (arr: Array<any>) => {
  return arr.map((member: any) => {
    member.id = member.u_id;
    delete member.u_id;
    return member;
  });
};