export const getStatusLabel = (options: any) => options?.label;

export const getSavedMembers = (data: any) => {
  return data.map((data: any) => {
    return {
      ...data.user,
      alloted_time: data.alloted_time ? data.alloted_time : null,
    };
  });
};

export const getMembers = (members: any) => {
  return members.map((data: any) => {
    return {
      user: data,
      alloted_time: data.alloted_time,
    };
  });
};

export const getStatus = (id: number, statusOptions: any) => {
  return statusOptions.filter((data: any) => data.value === id)[0];
};


export const getSavedMember = (data: any) => {
  return data.map((data: any) => {
    return data
  });
};

export const generatePayload = (saveData: any) => {
  return {
    default: {
      ...saveData,
    },
  };
};