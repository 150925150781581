import config from "../../environments/env-config";
import { projects } from "../constants/constants";
import { clearAll, deleteCookie, syWindowNavigate } from "../utils";


export const doLogOut=(()=>{
    deleteCookie(config.auth_token, config.auth_domain);
    clearAll();
    
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_PROJECT) {
      const url = `${config?.auth_url}/login?al=${projects[process.env.REACT_APP_PROJECT ?? '']}`;
      syWindowNavigate(url);
    } else {
      syWindowNavigate(`${config?.auth_url}/login`);
      // syWindowNavigate(`${config?.simpler_url}`);
    }
})