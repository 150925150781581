import { assignedProps } from "./not-assigned-interface";
import { headers } from "./config";
import NotAssignedTableRow from "./components/not-assigned-table-row/not-assigned-table-row";
import { useTranslation } from "react-i18next";


const NotAssignedPopup = (props: assignedProps) => {
    const { t } = useTranslation();

    const { data, onClick } = props;
    const onCardClick = (data: any) => {
        onClick(data);
    }

    return (
        <>
            <table className="w-100">
                <thead>
                    <tr className="text-center">
                        {headers.map((header) => (
                            <th key={header.key}>{t(header.label)}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="text-center">
                    {data?.length > 0 ? (
                        data.map((val: any) => (
                            <NotAssignedTableRow key={val.id} data={val} onClick={onCardClick} />
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6} className="text-center">
                                {t("no_data_found_shift")}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    )
}
export default NotAssignedPopup;