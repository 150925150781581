import EventCard from "./event-card";
import SyHtmlTooltip from "../../sy-html-tooltip";
import SyChip from "../../sy-chip";
import { memo } from "react";

type Props = {
  event: any;
  view: string;
  value: any;
  handleClick: (val: any, type: string) => void;
};

const EventSlot = ({ handleClick, event, view }: Props) => {
  const handleChipClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <span className="d-flex justify-content-center">
      {view === "week" ? (
        <div className="m-1 w-100">
          <EventCard data={event} value={event} onCardClick={handleClick} />
        </div>
      ) : (
        <SyHtmlTooltip
          htmlElement={
            <EventCard data={event} value={event} onCardClick={handleClick} />
          }
          children={
            <span onClick={handleChipClick}>
              <SyChip className={"m-1 p-3"} label={" "} color={event?.color} />
            </span>
          }
        />
      )}
    </span>
  );
};

export default memo(EventSlot);
