export const dateRepeat = [
    { 
      id: '1',
      value: 1,
      label:'day' ,
    },
    {
      id: '2',
      value: 2,
      label: 'week',
    },
    {
      id: '3',
        value: 3,
        label: 'month',
      },
  ];


  export const shiftStatus = [
    {
      id: '1',
      label:'active' ,
    },
    {
      id: '2',
      label:'in_active' ,
    },
    
  ];

export const wildcard = {
    includeCommunity: false,
    filter: [{ type: "module", filter_type: "include", mod_ids: [2] }],
    searchValue: ''
  };


  