import {
  addTime,
  extractTime,
  getDate,
  getDateLocaleString,
  getDateWithEndTime,
  getDateWithStartTime,
  getDayIndexFrom,
  getDayInMonth,
  getStartOfWeek,
} from "../../utils/dayjs";
import { syDeepClone } from "../../utils/helper";

const getDatesFrom = (startDate: any, noOfDays: number) => {
  const dates = [];
  for (let i = 0; i < noOfDays; i++) {
    const date = addTime(startDate, i, "days");

    dates.push({ date, events: [] });
  }
  return dates;
}

export const generateDatesFrom = (date: Date | string, view = "day") => {
  let newDates = [];
  if (view === "week") {
    const startOfWeek = getStartOfWeek(date);
    newDates = getDatesFrom(startOfWeek, 7);
  } else if (view === "month") {
    const startOfMonth = extractTime(date, "start", "month");
    const noOfDays = getDayInMonth(date);
    newDates = getDatesFrom(startOfMonth, noOfDays);
  } else {
    newDates = [{ date, events: [] }];
  }
  return newDates || [];
};

export const splitEventsByDay = (event: any) => {
  const splitEvents: any = [];

    const startDate = new Date(event.start);
    const endDate = new Date(event.end);

    // Calculate the number of days the event spans
    const startDay: any = getDateWithStartTime(startDate);
    const endDay: any = getDateWithStartTime(endDate)

    const dayCount = Math.ceil((endDay - startDay) / (1000 * 60 * 60 * 24)) + 1;

    for (let i = 0; i < dayCount; i++) {
      const currentDay = new Date(
        startDay.getFullYear(),
        startDay.getMonth(),
        startDay.getDate() + i
      );

      const newEvent = { ...event };

      if (i === 0) {
        // First day of the event
        newEvent.start = event.start;
        newEvent.end = getDateWithEndTime(currentDay).toString();
        newEvent.continuesFromPreviousDay = false;
        newEvent.continuesToNextDay = true;
        newEvent.eod = true;
      } else if (i === dayCount - 1) {
        // Last day of the event
        newEvent.start = getDateWithStartTime(currentDay).toString();
        newEvent.end = event.end;
        newEvent.continuesFromPreviousDay = true;
        newEvent.continuesToNextDay = false;
      } else {
        // Intermediate days
        newEvent.start = getDateWithStartTime(currentDay).toString();
        newEvent.end = getDateWithEndTime(currentDay).toString();
        newEvent.continuesFromPreviousDay = true;
        newEvent.continuesToNextDay = true;
        newEvent.eod = true;
      }

      splitEvents.push(newEvent);
    }

  return splitEvents;
}

const getSortedEvents = (events: any[]) => events?.sort((a, b) => {
  const startA = new Date(a.start as string);
  const startB = new Date(b.start as string);
  return startA.getTime() - startB.getTime();
});

export const generateCalendarList = (date: Date | string, data: any, view: string) => {
  return data.map((item: any) => {
    item["shifts"] = [];
    item.shifts.push(...generateDatesFrom(date, view))

    for (const event of syDeepClone(item.events)) {
      if (getDateLocaleString(event.start, 'year') < getDateLocaleString(event.end, 'year')) {
        item.events.splice(item.events.findIndex((a: any) => a.id === event.id) , 1)
        item.events.push(...splitEventsByDay(event));
      }
    }

    for (const event of getSortedEvents(item.events)) {
      let getIndex = view === 'week'?  getDayIndexFrom(event.start): (getDate(event.start) - 1);
      if (getDateLocaleString(item.shifts[getIndex]?.date, 'year') == getDateLocaleString(event.start, 'year')) {
        item.shifts[getIndex].events.push(event);
      }
    }

    return item;
  });
};

export const getViewBy = (id: number) => {
  switch (id) {
    case 1:
      return "week";
    case 2:
      return "month";
    case 3:
      return "day";
    default:
      return "day";
  }
};
