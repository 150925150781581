export const status = [
  {
    value: 1,
    label: "active",
  },
  {
    value: 2,
    label: "inactive",
  },
];

export const shift_type = [
  { id: 1, label: "General" },
  { id: 2, label: "Client" },
];

type memberValues = {
  user: any;
  alloted_time: any;
};

export const defaultValues = {
  scy_name: "",
  scy_color: "#1A9DD9",
  scy_category: null,
  members: [],
  scy_time_bound: false
};

export const type_id_icon: any = {
  101: "mood",
  102: "apartment",
  103: "emoji_emotions",
  104: "business",
  105: "contact_phone",
  3: "drive_eta",
  2: "meeting_room",
  4: "devices",
  99: "person",
};
