import dayjs, { Dayjs, ManipulateType, OpUnitType, QUnitType } from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
const dateFormat: any = {
  date: "DD.MM.YYYY",
  month: "MM.DD.YYYY",
  year: "YYYY-MM-DD",
  onlyYear: "YYYY",
  dateTime: "YYYY-MM-DDTHH:mm",
  setdate: "DD",
  setdateFormate:'D',
  time: "HH:mm",
  weekDay:'ddd',
  fullWeekDay:'dddd',
  dayOfMonth:'dd',
  timeWithSecond: "HH:mm:ss",
  timeWithSection: "HH:mm:A",
  dateFormat: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  utcFormate :'YYYY-MM-DD HH:mm:ss [GMT]Z',
  dateTimeHour: 'YYYY-MM-DD HH:mm:ss'
};

const INCLUDE_RANGE_CONFIG: any = {
  both: "[]",
  none: "()",
  onlyStart: "[)",
  onlyEnd: "(]",
};

dayjs.extend(weekOfYear);
export const getDateLocaleString = (date: any, mode: string) => {
  const format = dateFormat[mode];
  if (date) {
   
    return dayjs(date).format(format);
  }
  return "";
};
export const convertMinsToHrsMins = (value: any) => {
  const hours = Math.floor(value / 60);
  const minutes = Math.round(value % 60);
  if (hours > 0 && minutes > 0) {
    return `${hours}:${minutes}`;
  } else if (hours > 0) {
    return `${hours}:00`;
  } else if (hours === 0) {
    return `00:${minutes}`;
  } else {
    return 0;
  }
};
export const getTimeDifference = (time: any, range: any) => {
  const startTime = getDateLocaleString(time, "time");
  let Range: any = range;
  if (!range.includes(":")) {
    const data = convertMinsToHrsMins(range);
    Range = data;
  }
  if (Range.includes(":")) {
    const data = startTime.split(":");
    const timeRange = Range.split(":");
    let hour: any = parseInt(data[0]) + parseInt(timeRange[0]);
    let minute: any = parseInt(data[1]) + parseInt(timeRange[1]);
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (minute > 59) {
      const data = minute - 60;
      minute = "0" + data;
      hour = hour + parseInt(timeRange[0]);
    }
    if (hour > 24) {
      const data = hour - 24;
      hour = data;
    }
    if (hour == 24) {
      hour = "00";
    } else if (hour < 10) {
      hour = "0" + hour;
    }
    const updateTime = hour + ":" + minute;
    const updateStartTime = getDateLocaleString(time, "year");
const setdateFormate =dayjs(`${updateStartTime}T${updateTime}`)
    return setdateFormate;
  } else {
    return "";
  }
};
export const setDate = (date: string, mode: number) => {
  if (date) {
    return dayjs(date).add(mode, "month");
  }
  return "";
};
export const getUTCTime = (date:any,time:any) => {

const setTime =getDateLocaleString(time,'time')

const splitTime = setTime?.split(":");
 const updatetime =dayjs(date).set('hour', parseInt(splitTime[0])).set('minute', parseInt(splitTime[1])).set('second', 0)

const utcTime: any = { ...updatetime };
 const newDate = new Date(utcTime.$d)

 const utcDate = newDate.toISOString();
 return utcDate;
};
export const currentTime =()=>{
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const railwayTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  return railwayTime
}
export const timeValidation =(date:any)=>{
  const timeRange = date.split(":");
  const startTimeValidation = dayjs().set('hour', parseInt(timeRange[0]) ).set('minute',  parseInt(timeRange[1])).startOf('minute');
  return startTimeValidation
}


/**
 * returns starting weekday(monday) and ending weekday(sunday) for given date
 *
 * @param date  Date
 * @returns     object containing start and end dates of week
 */
export const getWeekRange = (date: any) => {

  const day = new Date(date).getDay(),
    startDiff = new Date(date).getDate() - day + (day === 0 ? -6 : 1),
    endDiff = new Date(date).getDate() + (day === 0 ? 0 : 7 - day);

  return {
    start: new Date(new Date(date).setDate(startDiff)),
    end: new Date(new Date(date).setDate(endDiff))
  };
}

/** 
 * @param   date  Date
 * @returns       number of week for given date
 */
export const getWeekByDate = (date: Date | Dayjs):Number => {
  return dayjs(date).week();
}

/**
 * get days of week starting from monday
 * 
 * @param format     string     of format of day
 * @returns          array      of days of week starting from monday
 */
export const getWeekDays = (format: string = 'ddd') => Array.from({ length: 7 }, (_, i) => ({
  day: dayjs().day(i + 1).format(format),
}));


/**
 * 
 * @param date
 * @param mode
 * @param val
 * @returns
 */
export const setDayjsDate = (date: any, mode: dayjs.UnitType, val: number) => {
  return dayjs(date).set(mode, val);
}

/**
 * @param date            date           input date
 * @param addValue        number         value to be added from date
 * @param addBy           string         add by value
 *
 * @returns date added from input date
 */
export const addTime = (date: any, addValue: number, addBy: ManipulateType) => {
  return dayjs(date).add(addValue, addBy);
}


/** 
 * get date with current time
 * @param date    date
 * @returns       date with current time 
 */
export const getDateWithCurrentTime = (date: Date | Dayjs) => {
  return dayjs(date)
    .set("hour", dayjs().hour())
    .set("minute", dayjs().minute())
    .set("second", dayjs().second());
};

/**
 * Generates a list of times based on the given mode.
 * 
 * @param {("time" | "timeWithSecond" | "timeWithSection")} mode - The format mode for the time generation. 
 * @returns {string[]} - An array of formatted time strings for each hour of the day.
 */
export const generateTimes = (mode: "time" | "timeWithSecond"|"timeWithSection") => {
  const times = [];
  const format = dateFormat[mode];
  for (let hour = 0; hour < 24; hour++) {
    times.push(dayjs().hour(hour).minute(0).format(format));
  }
  return times;
};

/**
 * Gets the start date of the week (starting from Monday) for a given date.
 * 
 * @param {Date} date - The date from which to calculate the start of the week.
 * @returns {Dayjs} - The Dayjs object representing the start of the week (Monday).
 */
export const getStartOfWeek = (date: Date | string) => {
  const newDate = dayjs(date);
  if (newDate.day() === 0) {
    return newDate.subtract(1, "week").startOf("week").day(1);
  } else {
    return newDate.startOf("week").day(1);
  }
};

/**
 * Gets the total number of days in the month of the provided date.
 * 
 * @param {Date} date - The date to calculate the number of days for.
 * @returns {number} - The number of days in the month of the given date.
 */
export const getDayInMonth = (date: Date | string) => {
  return dayjs(date).daysInMonth();
};

/**
 * Gets the start date of the month for a given date.
 * 
 * @param {Date} date - The date to get the start of the month for.
 * @returns {Dayjs} - The Dayjs object representing the start of the month.
 */
export const getStartOfMonth = (date: Date) => {
  return dayjs(date).startOf("month");
};

/**
 * Checks if a given date is the same day as another date.
 * 
 * @param {string} date - The date to compare (in string format).
 * @param {Date} compareDate - The date object to compare with.
 * @returns {boolean} - `true` if the two dates are the same day, `false` otherwise.
 */
export const isSameDay = (date: string, compareDate: Date) => {
  return dayjs(date).isSame(dayjs(compareDate), "day");
};

/**
 * Compares two dates based on the provided comparison type ("before" or "after").
 * 
 * @param {any} baseDate - The base date to compare from.
 * @param {any} compareDate - The date to compare against.
 * @param {'before' | 'after'} type - The comparison type, either 'before' or 'after'.
 */
export const compareDate = (baseDate:any,compareDate:any,type:'before'|'after')=>{
  if(type == 'before'){
    return dayjs(baseDate).isBefore(dayjs(compareDate))
  }
  if(type == 'after'){
    return dayjs(baseDate).isAfter(dayjs(compareDate))
  }
}

/**
 * Calculates the difference between two dates in the specified units.
 * 
 * @param {any} startDate - The starting date to compare.
 * @param {any} endDate - The ending date to compare.
 * @param {'month' | 'year' | 'hour' | 'minute' | 'second'} units - The units to measure the difference in.
 * @returns {number} - The difference between the two dates in the specified units.
 */
export const getDateDiff =(startDate:any,endDate:any,units: QUnitType | OpUnitType='minute')=>{
  return dayjs(endDate).diff(dayjs(startDate),units)
}

/**
 * Returns the start of the day for a given date.
 * 
 * @param {any} date - The date to get the start of the day for.
 * @returns {Dayjs} - The Dayjs object representing the start of the day.
 * @returns {Dayjs} - A Dayjs object representing the start of the day (00:00:00) for the given date.
 * 
 */
export const getStartOfDay = (date: any) => {
  return dayjs(date).clone().startOf('day');  
};

/**
 * Gets the date object with the start of the day (00:00:00) for the given day.
 * 
 * @param {any} day - The date to get the start of the day for.
 * @returns {Date} - A Date object representing the start of the day (00:00:00).
 */
export const getDateWithStartTime = (day:any)=>{
  return dayjs(day).startOf('day').toDate();
}

/**
 * Gets the date object with the end of the day (23:59:59) for the given day.
 * 
 * @param {any} day - The date to get the end of the day for.
 * @returns {Date} - A Date object representing the end of the day (23:59:59).
 */
export const getDateWithEndTime = (day:any)=>{
  return dayjs(day).endOf('day').toDate();
}

/**
 * Generates a list of dates based on the provided view type and starting date.
 * 
 * @param {1 | 2} view - The view type
 * @param {Date} date - The starting date for generating the week/month.
 * @returns {Array<{date: Dayjs, events: any[]}>} - An array of objects representing the dates in the week or month, with each date and an empty events array.
 */
export const generateWeekDates = (view: 1 | 2, date: Date) => {
  const dates = [];
  const startDate = view === 1 ? getStartOfWeek(date) : getStartOfMonth(date);
  const totalDays = view === 1 ? 7 : getDayInMonth(date);

  for (let i = 0; i < totalDays; i++) {
    const currentDate = startDate.clone().add(i, "days");
    dates.push({ date: currentDate, events: [] });
  }

  return dates;
};

/**
 * to check if two events are ovelapping
 * @param eventA        object      containing start time and end time
 * @param eventB        object      containing start time and end time
 * @returns             boolean     true if the events are overlapping
 */
export const isOverlapping = (eventA: any, eventB: any) => {
  const endA = addTime(eventA.end || eventA.start,1,"minutes")

  const endB = addTime(eventB.end || eventB.start,1, "minutes");

  // return startA.isBefore(endB) && endA.isAfter(startB);
  return compareDate(eventA.start,endB,"before") && compareDate(endA,eventB.start,'after');
};

/**
 *  extract start or end time of given unit of time
 * @param       date      Date
 * @param       extract   `start` | `end`    for start time or end time
 * @param       unit      string             unit of time. eg, day, week, etc,..
 * @param       utc       boolean            true for utc time
 * @returns start or end time of given unit of time
 */
export const extractTime = (date: any, extract: 'start' | 'end', unit: OpUnitType, utc: boolean = false, format?: string) => {
  date = dayjs(date).clone();

  if (utc) {
    date = date.utc();
  }

  if (extract === 'start') {
    date = date.startOf(unit);
  }

  if (extract === 'end') {
    date = date.endOf(unit);
  }

  if (format) {
    format = dateFormat[format];
    date = date.format(format)
  }

  return date;
}

/**
 * to check if a date or time is between a range of time
 * 
 * @param date                date
 * @param startRange          date          start date of range
 * @param endRange            date          end date of range
 * @param precision           string        of unit type for unit precision eg, day, month, week
 * @param includeRange        string        of type to include start and end ranges
 * @returns                   boolean       true if given date or time exists inside the range
 * @ref https://day.js.org/docs/en/plugin/is-between
 */
export const isDateBetween = (
  date: Date | string,
  startRange: Date | string,
  endRange: Date | string,
  precision: OpUnitType | null = null,
  includeRange: 'both' | 'none' | 'onlyStart' | 'onlyEnd' = 'both',
) => {
  return dayjs(date).isBetween(dayjs(startRange), dayjs(endRange), precision, INCLUDE_RANGE_CONFIG[includeRange]);
};


export const getDayIndexFrom = (date: Date | string) => {
  const index = new Date(date).getDay();
  return index === 0 ? 6 : index - 1;
};


export const getDate = (date: Date | string) => {
  return new Date(date).getDate();
}
