import { memo, useCallback, useMemo, useState } from "react";
import SyChip from "../../sy-chip";
import SyHtmlTooltip from "../../sy-html-tooltip";
import { getDateLocaleString } from "../../../utils/dayjs";
import { t } from "i18next";
import { RenderEventsProps } from "../sy-calendar-model";
import EventCard from "./eventCard";
import { getLightenColor, getTextColor } from "../../../utils/theming";
import Avathar from "../../../../projects/shift/modules/shift-module/components/avathar/avathar";

const RenderEvents = (props: RenderEventsProps) => {
  const {
    eventDetails,
    calendarView = 1,
    eventHeight = 70,
    onEventClick = () => {},
    listView,
  } = props;

  const [tooltipContent, setTooltipContent] = useState<any>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{x: number, y: number}>({ x: 0, y: 0 });

  const {
    id,
    start,
    end,
    continuesToNextDay,
    continuesFromPreviousDay,
    style,
    members,
    capacity,
    color,
    eod
  } = eventDetails || {};

  /**
   * Format event's start and end time
   */
  const startTime = useMemo(()=>getDateLocaleString(start, "time"),[start]);
  const endTime = useMemo(() => getDateLocaleString(end, "time") === "23:59" && eod ? "24:00" :getDateLocaleString(end,"time"), [end]);
  /**
   * event color and member,event height
   */

  const chipStyle = {
    ...style,
    backgroundColor: calendarView == 1 ? getLightenColor(color) : color, 
    color:getTextColor(color)
  };
  
  const memberLength = useMemo(()=>members.length,[members])
  const Height = useMemo(()=>Number(style.height.split(/\D/)[0]),[style?.height])


  const handleEventMouseHover = useCallback((event: React.MouseEvent) => {
    setTooltipPosition({ 
      x: event.clientX, 
      y: event.clientY 
    });

    if(calendarView == 1 && Height <= 60){
      setTooltipContent(
        !listView &&<EventCard data={eventDetails}  detailedView={ false}/>
      );
    }
    else if(calendarView == 2){
      setTooltipContent(
       <EventCard data={eventDetails}  detailedView={ false}/>
      );
    }
  }, [eventDetails, startTime, endTime, memberLength]);

  const handleMouseLeave = useCallback(() => {
    setTooltipContent(null);
  }, []);

  const AvatarList = memo(()=>{
    const memoizedAvatarList = useMemo(()=>{
      return  members?.map((data: any, index: number) => (
        <Avathar
          key={index}
          data={data}
          showName={true}
        />
      ))
    },[members])
    return <div className="text-center">{memoizedAvatarList}</div>
  })

  const onClickEvent = useCallback(() => {
    onEventClick(eventDetails);
  }, [eventDetails]);

  return (
    <>
      {tooltipContent && (
        <div 
          style={{
            position: 'fixed',
            top: tooltipPosition.y + 10, 
            left: tooltipPosition.x,
            zIndex: 100,
          }}
        >
          {tooltipContent}
        </div>
      )}
      <div
        className={`event calender-theme-color py-1 px-2 ${
          continuesToNextDay ? "x" : ""
        } ${continuesFromPreviousDay ? "y" : ""}`}
        key={id}
        style={chipStyle}
        onClick={onClickEvent}
        onMouseEnter={handleEventMouseHover}
        onMouseLeave={handleMouseLeave}
      >
        {calendarView === 1 && Height >= 32 ? (
          <div className="pb-1 d-flex flex-column align-items-center">
            <SyChip
              label={`${startTime}-${endTime}`}
              color={color}
              size="sm"
            />
             <p className={`capacity mb-0 text-center xm-font d-flex justify-content-center align-items-center`}>
               {`${members.length} ${t("from_shift")} `}{!capacity ? <span className="infinity">&#8734;</span> : capacity}{" "}
             </p>
             {Height >= 80 && <AvatarList/>}
          </div>
        ) : (
          <SyHtmlTooltip
            htmlElement={
              <EventCard
                data={eventDetails}
                detailedView={calendarView == 2 ? false : true}
              />
            }
            children={
              <span>
                <>
                </>
              </span>
            }
          />
        )}
      </div>
    </>
  );
};

export default memo(RenderEvents);