import Stylesheet from "./style.module.scss";
import sodisys_logo from "./sodisys_logo.png";
import { clearAll, deleteCookie, getJsonData } from "../../../../../../shared/utils";
import { useContext, useEffect, useState } from "react";
import SyIcons from "../../../../../../shared/components/sy-icons";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../../context";
import config from "../../../../../../environments/env-config";
import { useTranslation } from "react-i18next";
import Service from "../../../auth-module/service/service";
import { useMutation } from "@tanstack/react-query";
import { SyLoadingIcon, SyMenu } from "../../../../../../shared/components";
import { HEADER_MENU_CONFIG } from "./app-header-config";
import { useSelector } from "react-redux";
import { getuserDetailReducer } from "./../../../../feature/common-slice";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import { doLogOut } from "../../../../../../shared/cmn/cmn-service";

type Props = {};

const AppHeader = (props: Props) => {
  const { t } = useTranslation();
  const userDetail = useSelector(getuserDetailReducer);

  const { mutate, isLoading } = useMutation({
    mutationFn: Service.signOut,
    onSuccess() {
      doLogOut()
    },
  });

  function handleLogOut() {
    mutate({});
  }

  const onClickMenu = (val: any) => {
    if (val.action === "logout") mutate({});
  };

  const handleDropdownItemClick = (e: any) => {
    e.stopPropagation();
    handleLogOut();
  };

  const getFunctionName = (type: any) => {
    switch (type) {
      case "1":
        return "company_admin";
      case "100":
        return "admin";
      case "2":
        return "manager";
      case "3":
        return "employee";
      case "4":
        return "manager_employee";
      case "101":
        return "client";
      case "103":
        return "sta_client";
      default:
        return "";
    }
  };

  const backToSodisys=()=>{
    return window.open(`${config.app_url}`, "_blank");
  }

  const backToSodisysIcon = <SyIcons iconStyle="outlined" name="undo" iconType="symbols"/>

  return (
    <nav className={`${Stylesheet.header_menu} sticky-top`}>
      <div className="d-flex p-2 pb-1 justify-content-between">
        <div className="d-flex align-items-center">
          <div className={`${Stylesheet.logo_container} ms-4`}>
            <img
              className={`${Stylesheet.logo} ms-3 mt-2`}
              src={sodisys_logo}
            />
          </div>
        </div>

      
        <div className="d-flex align-items-center ">
          {/* <div className={`${Stylesheet.timer} me-5`}>
            <span className="material-icons-outlined p-2">timer</span>
          </div> */}
          
          <Sybutton
            type={"button"}
            className={"iconButton me-2 d-flex align-items-center d-block d-sm-none"}
            size={"sm"}
            onBtnClick={backToSodisys}
            label={backToSodisysIcon}
          />

          <Sybutton
            type={"button"}
            className={"primarybutton me-2 d-none d-sm-block"}
            size={"sm"}
            label={t("back_to_sodisys")}
            onBtnClick={backToSodisys}
          />

          <SyMenu
            onClick={onClickMenu}
            vertical={"bottom"} horizontal={"right"} 
            menuItems={HEADER_MENU_CONFIG}
            menuBtn={
              <div
                className={`${Stylesheet.menu} pointer w-100 h-100`}
                id="account-menu"
                aria-haspopup="true"
              >
                <div className="d-flex flexWrap justify-content-between">
                  <div className="ps-2">
                    <span>{userDetail?.username}</span>
                    <br />
                    <span className={`${Stylesheet.functionname} `}>
                      {t(userDetail?.function
                        ? userDetail?.function
                        : getFunctionName(userDetail?.type))}
                    </span>
                  </div>
                  <SyIcons
                    iconStyle="outlined"
                    className={`${Stylesheet.ddIcon}`}
                    name={"expand_more"}
                  />
                </div>
              </div>
            }
          />
        </div>
      </div>
    </nav>

    //     <nav className="navbar navbar-light bg-light">
    //   <div className="container-fluid">
    //     {/* <a className="navbar-brand">Navbar</a> */}
    //     <div></div>
    //     <form className="d-flex">
    //     <SyMenu />
    //       {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"> */}
    //       {/* <button className="btn btn-outline-success" type="submit">Search</button> */}
    //     </form>
    //   </div>
    // </nav>
  );
};

export default AppHeader;
