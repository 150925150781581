import { getDateLocaleString, getWeekRange } from "../../../../../../shared/utils/dayjs";

/**returns weeks first day (monday) or months first day acc to schudeler data */
export const getInitialDate = (val: any) => {

    if (val.length <= 7) {
        val = val.find((data: any) => data.day == 'Mon');
    } else {
        val = val.find((data: any) => data.day == '1');
    }

    return val?.date ?? null;
}

/**returns weeks first day (monday) or months first day acc to scheduler view */
export const getPayloadDate = (val: any, schedulerView:any) => {

    if (schedulerView == 1) {
        const {start} = getWeekRange(val);
        val = start;
    } else {
        val = new Date(val.getFullYear(), val.getMonth(), 1);
    }    

    return getDateLocaleString(val, 'year') ?? null;
}

export const generatePayload = (schedulerDate: any, schedulerType: any, userId: any, masterType: any, memberDetails?: any) => {
    return {
        date: getPayloadDate(schedulerDate, schedulerType),
        type: schedulerType === 1 ? "week" : "month",
        user_id: memberDetails?.id || userId,
        user_type: masterType,
    };
};