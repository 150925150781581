import config from "../../environments/env-config"
import { projects } from "../constants/constants";
import { syWindowNavigate } from "../utils";
interface props{
    children:any
}

const ProtectedRoute = ({  children }:props) => {
  if (process.env.REACT_APP_ENV ) {
    syWindowNavigate(`${config.auth_url}/login?al=${projects[process.env.REACT_APP_PROJECT ?? '']}`);

    return null;
  }

  return children;
};
export default ProtectedRoute;