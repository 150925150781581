//react hooks
import { useState, useEffect, useMemo, memo } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//styles
import Stylesheet from "./style.module.scss";
//utils
import { setQueryParams } from "../../../../../../shared/utils";
import { findItemIn, getShiftDashBoardParamPayload, hasOwnObjectIn } from "../../../../../../shared/utils/helper";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
//services
import shiftService from "../../service/shift-service";
//config
import { EVENT_VIEW_CONFIG, getHeaderButtons, NAV_MENU_CONFIG } from "./config";
//redux-slice
import { getNavDetails, setAvailableList, deleteCategory, setCategoryList, deleteSubCategory, setSubCategoryList, upsertCategory, upsertSubCategory } from "../../../../feature/shift-slice";
import { getAvailableList, setMemberList, setShiftList } from "../../../../feature/shift-slice/scheduler-slice";
import { getuserDetail } from "../../../../feature/common-slice";
//helper
import { getNearbyNavItem, getParamValue, getShiftPayload, menuIcon, updatedData } from "./helper";
//components
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import Popup from "../../../../../../shared/components/sy-model";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
import AvailableList from "../../components/available-list";
import ShiftPopup from "../../popup/new-shift-popup";
import SubCategoryManage from "../../popup/create-shift-sub-category";
import CategoryManage from "../../popup/create-shift-category";
import DashboardNav from "./components/dashboard-nav/dashboard-nav";
import MemberList from "../../components/member-list";
import { NotAssignedPopup } from "../../popup/not-assigned-popup";
import { SyMenu } from "../../../../../../shared/components";
import SyEventCalender from "../../../../../../shared/components/sy-event-calender";
import { createAbortSignal } from "../../../../../../shared/utils/axios";
import SyDaywiseEventList from "../../../../../../shared/components/sy-event-calendar-list";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import { useNavigate } from "react-router";


const listSignal = createAbortSignal(),
  membersListSignal = createAbortSignal(),
  assignedListSignal = createAbortSignal(),
  availableListSignal = createAbortSignal();

const DashboardScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const params = getParamValue(searchParams);
  const navigate:any = useNavigate();

  //--states--//
  const [openShiftPopup, setOpenShiftPopup] = useState(false);
  const [shiftId, setShiftId] = useState<any>(); // for shift edit
  const [openCategoryPopup, setOpenCategoryPopup] = useState(false);
  const [openNavConfirmPopup, setOpenNavConfirmPopup] = useState(false);
  const [openSubCategoryPopup, setOpenSubCategoryPopup] = useState(false);
  const [openShiftAssignedPopup, setOpenShiftAssignedPopup] = useState(false);
  const [defaultShiftValues, setDefaultShiftValues] = useState<any>({});// data from which column is clicked
  const [schedulerType, setSchedulerType] = useState(1); // scheduler view
  const [width, setWidth] = useState(window.innerWidth); // design
  const [highlightIds, setHighlightIds] = useState([]); // shift highlight
  const [schedulerDate, setSchedulerDate] = useState(new Date()); // start date of scheduler
  const [edit, setEdit] = useState(false);
  const [shiftView, setShiftView] = useState<any>('user');

  //-selectors--//
  const {category: {list: cgyList, selected: cgySelected}, subCategory: {list: scyList, selected: scySelected}} = useSelector(getNavDetails);
  const available = useSelector(getAvailableList);
  const userDetail = useSelector(getuserDetail);

  const shift_admin: any = userDetail?.type == "1";

  useEffect(() => {
    getCategoryList("init");
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  //--mutation--//

  //assigned
  const { data: unassignedShiftList, mutate: getShiftAssigned } = useMutation({
    mutationFn: shiftService.getShiftAssignedData,
  });

  //available
  const { mutate: availableList, isLoading: getAvailableLoading } = useMutation(
    {
      mutationFn: shiftService.getAvailableList,
      onSuccess(data) {
        dispatch(setAvailableList(data.data));
      },
    }
  );

  //memberList
  const { data: memberList , mutate: shiftCategoryMemberList, isLoading: getMemberLoading } = useMutation({
    mutationFn: shiftService.getCategoryMemberList,
    onSuccess(data: any) {
      dispatch(setMemberList(data.data))
    },
  });

  //exportlist
  const { mutate: getExportList } = useMutation({
    mutationFn: shiftService.getExportList,
    onSuccess(data: any) {
      window.location.href = data.data.file;
    },
  });

  const { mutate: getCategoryList, isLoading: cgyLoading } = useMutation({
    mutationFn: shiftService.getCategoryGroup,
    onSuccess(data: any, variables) {
      const list = data?.data?.data
      dispatch(setCategoryList(list));
      if (variables === 'init') {
        const paramCgyExists = hasOwnObjectIn(list, params?.cgy, "cgy_id");
        const cgyData = findItemIn(list, params?.cgy, "cgy_id");
        if (paramCgyExists) {
          getSubCategoryList({id: params.cgy, mode: 'init'});
          dispatch(upsertCategory(cgyData));
        }
        if (!params.scy && list.length) {
          onClickCategory(paramCgyExists ? cgyData : list[0]);
        }
      }
    },
  });

  const { mutate: categoryDelete } = useMutation(
    {
      mutationFn: shiftService.deleteShiftCategory,
      onSuccess(data: any, variables: any) {
        dispatch(deleteCategory(variables));
        if (cgyList.length > 1) {
          onClickCategory(getNearbyNavItem(cgyList, variables));
        } else {
          setQueryParams({url:''});
          shiftData.data = [];
        }
        const message = data.data.message;
        SyToast(t(message), "top-right", "short", "success");
      },
    }
  );

  const { mutate: subCategoryDelete } = useMutation({
    mutationFn: shiftService.deleteShiftGroup,
    onSuccess(data, variables) {
      dispatch(deleteSubCategory(variables));
      if (scyList.length > 1) {
        onClickSubCategory(getNearbyNavItem(scyList, variables, 'scy_id'));
      } else {
        onClickCategory(cgySelected);
      }
      const message = data.data.message
      SyToast(t(message), "top-right", "short", "success");
    },
  });

  const { mutate: getSubCategoryList, isLoading: scyLoading } = useMutation({
    mutationFn: shiftService.getShiftGroup,
    onSuccess(data: any, variables: any) {
      const { data: scyList } = data;
      dispatch(setSubCategoryList(scyList))
      if (variables.mode === 'init') {
        const paramScyExists = hasOwnObjectIn(scyList, params?.scy, "scy_id");
        if (paramScyExists) {
          onClickSubCategory(findItemIn(scyList, params.scy, "scy_id"));
        } else {
          cgyList.length && onClickCategory(cgyList[0]);
        }
      }
    },
  });

  const {data: shiftData, mutate: getShiftList, isLoading: shiftLoading} = useMutation({
    mutationFn: shiftService.getShiftList,
    onSuccess(data: any, variables: any) {
      dispatch(setShiftList(data.data));
    },
  });

  const getList = (payload: any) => {
    getShiftList({payload, signal: listSignal()});
    getShiftAssigned({payload,signal:assignedListSignal()});
  };

  const onCloseCategoryPopup = () => {
    setOpenCategoryPopup(false);
    setEdit(false);
  };

  const onCloseShiftAssignedPopup = () => {
    setOpenShiftAssignedPopup(false);
  };

  const onExportButton = () => {
    getExportList(getShiftPayload(schedulerDate, schedulerType, cgySelected?.cgy_id, scySelected?.scy_id));
  };

  const onNotAssignedShift = () => {
    // const payload = getShiftPayload(schedulerDate, schedulerType, cgySelected?.cgy_id, scySelected?.scy_id,true);
    // getShiftAssigned({payload,signal:assignedListSignal()});
    if (unassignedShiftList?.data) {
      setOpenShiftAssignedPopup(true);
    }
  };

  const onCloseSubCategoryPopup = () => {
    setOpenSubCategoryPopup(false);
    setEdit(false)
  };

  const setParam = (data: any, from: any) => {
    if (from === "categoryClick") {
      setQueryParams(getShiftDashBoardParamPayload(`cgy=${data?.cgy_id}`));
    } else if (from === "shiftgroup") {
      setQueryParams(
        getShiftDashBoardParamPayload(
          `cgy=${data.scy_category}/scy=${data?.scy_id}`
        )
      );
    }
  };

  const onClickCategory = (data: any) => {
    dispatch(upsertCategory(data));
    getSubCategoryList({id: data?.cgy_id});
    dispatch(setSubCategoryList([]));
    setParam(data, "categoryClick");
    // updateMemberList(schedulerDate, data?.cgy_id);
    const payload = getShiftPayload(schedulerDate, schedulerType, data?.cgy_id, undefined, undefined, shiftView ?? '')
    getList(payload);
  };

  const onClickSubCategory = (data: any) => {
    setParam(data, "shiftgroup");
    dispatch(upsertSubCategory(data));
    // setIsListLoading(navDetails?.clickedData?.selected?.scy_id !== data?.scy_id); // for future use
    // updateMemberList(schedulerDate, cgySelected?.cgy_id, data?.scy_id);
    const payload = getShiftPayload(schedulerDate, schedulerType, cgySelected?.cgy_id, data?.scy_id, undefined, shiftView ?? '')
    getList(payload);
  };

  const onSubCategorySuccess = (id: any) => {
    const cgyId = cgySelected?.cgy_id;
    const scyId = id ?? scySelected?.scy_id;
    // updateMemberList(schedulerDate, cgyId, scyId);
    getList(getShiftPayload(schedulerDate, schedulerType, cgyId, scyId, undefined, shiftView ?? ''));
  };

  const onClickEditUnassignedList = (val: any) => {
    setShiftId(val);
    setOpenShiftPopup(true);
  };

  const onShiftPopupClose = () => {
    setOpenShiftPopup(false);
    setShiftId(null)
  };

  const updateMemberList = (date: Date, cgyId: any = null, scyId: any = null, view: any = null) => {
    if (date) {
      view = view ?? schedulerType;
      const payload = getShiftPayload(date, view, cgyId, scyId);
      const payloadWithType = getShiftPayload(date, view, cgyId, scyId, true);
      if (cgyId) {
        shiftCategoryMemberList({payload: payloadWithType, signal: membersListSignal()});
        getShiftAssigned({payload, signal: assignedListSignal()});
      }
      if (scyId) {
        availableList({payload: payloadWithType, signal: availableListSignal()});
      }
    }
  };

  const handleMemberSelect = (val: any) => {
    setHighlightIds(val);
  };

  const onCgySave = (val: any) => {
    onClickCategory(val);
  };

  const getShiftPopupData = ( id?: any, defaultValues: any = {}) => {
    const {date, ...rest} = defaultValues;
    return {
      scheduler_date: schedulerDate,
      scy_id: scySelected?.scy_id,
      cgy_id: cgySelected?.cgy_id,
      shift_id: id ? id : null,
      default_date: date ? date : void 0,
      ...rest,
    };
  };

  const onConfirmNavItemDelete = () => {
    if (scySelected?.scy_id) {
      subCategoryDelete(scySelected.scy_id);
    } else if (cgySelected?.cgy_id) {
      categoryDelete(cgySelected.cgy_id);
      dispatch(setSubCategoryList([]));
    }
  }

  const onShiftSaveSuccess = () => {
    // updateMemberList(schedulerDate, cgySelected?.cgy_id, scySelected?.scy_id);
    const payload = getShiftPayload(schedulerDate, schedulerType, cgySelected?.cgy_id, scySelected?.scy_id, undefined, shiftView ?? '')
    getList(payload);
    if (openShiftAssignedPopup && unassignedShiftList?.data?.shiftNotAssignedCount === 1) {
      onCloseShiftAssignedPopup();
    }
  }

  const onMenuItemClick = (val: any) => {
    switch (val.action) {
      case 'edit':
        if (cgySelected?.cgy_id) {
          if (scySelected?.scy_id) {
            setOpenSubCategoryPopup(true);
          } else {
            setOpenCategoryPopup(true);
          }
          setEdit(true);
        }
        break;
      case 'delete':
        if (scySelected?.scy_id || cgySelected?.cgy_id) {
          setOpenNavConfirmPopup(true);
        }
        break;
    }
  }

 const onHeaderClick = (val: any, type: any) => {

  if (val === "add") {
    setDefaultShiftValues({date: val.date});
    setOpenShiftPopup(true);
  }

  if (val === 1 || val === 2 || val === 3) {
    setSchedulerType(val);
    // updateMemberList(schedulerDate, cgySelected?.cgy_id, scySelected?.scy_id, val);

    getList(getShiftPayload(schedulerDate, val, cgySelected?.cgy_id, scySelected?.scy_id, undefined, shiftView ?? ''));
  }

  if (type === 'date') {
    setSchedulerDate(val);
    // updateMemberList(val, cgySelected?.cgy_id, scySelected?.scy_id, schedulerType);
    getList(getShiftPayload(val, schedulerType, cgySelected?.cgy_id, scySelected?.scy_id, undefined, shiftView ?? ''));
  }

 };

 const onCellClick = (e:any, type: string) => {
  if(type === "event"){
    setShiftId(e.id);
    setOpenShiftPopup(true);
  }
  if (type === "empty" && scySelected?.scy_id) {
    setDefaultShiftValues({...e, [shiftView]: e.entity});
    setOpenShiftPopup(true);
  }
 }

  const onNavClick = (mode: string, value: any) => {
    switch (mode) {
      case 'addCgy':// add category
        setOpenCategoryPopup(true);
        break;
      case 'addScy': // add sub category
        setOpenSubCategoryPopup(true);
        break;
      case 'selectCgy':
        onClickCategory(value);
        break;
      case 'selectScy':
        onClickSubCategory(value);
        break;
    }
  }

  const shiftList = useMemo(() => {
    return updatedData(shiftData?.data, highlightIds);
  }, [highlightIds, shiftData?.data]);

  const handleViewChange = ({value}: any) => {
    setShiftView(value);
    getList(getShiftPayload(schedulerDate, schedulerType, cgySelected?.cgy_id, scySelected?.scy_id, undefined, value ?? 'user'));
  };

  const getLabel = (val: any) => t(val.name);

  const shiftViewDropdown = (
    <span className={`${Stylesheet.dropdown}`}>
      <Dropdown
        options={EVENT_VIEW_CONFIG.options}
        value={EVENT_VIEW_CONFIG.defaultValue}
        placeholder={t(EVENT_VIEW_CONFIG.label)}
        handleChange={handleViewChange}
        getoptlabel={getLabel}
        clearIcon={true}
      />
    </span>
  );

  const onShiftEntityClick = (val : any) => {
    const entityId = val?.entity?.id;
    if(entityId && shiftView === "user"){
      setQueryParams({url:`/myshift/id=${entityId}`})
      navigate(`/myshift?id=${entityId}`);
    }
  }

  return (
    <>
      {openSubCategoryPopup && (
        <Popup
          open={openSubCategoryPopup}
          close={onCloseSubCategoryPopup}
          onClose={onCloseSubCategoryPopup}
          model_type={"responsive"}
          style={{ width: "1000px" }}
          title={edit ? t("edit_shift_plan_shift") : t("create_shift_plan_shift")}
        >
          <SubCategoryManage
            onPopupClose={onCloseSubCategoryPopup}
            mode={edit?"edit":"add"}
            params={{scy_category: cgySelected?.cgy_id, scy_id: edit ? scySelected?.scy_id : null}}
            onSubmitSucess={onSubCategorySuccess}
          />
        </Popup>
      )}
      {openCategoryPopup && (
        <Popup
          open={openCategoryPopup}
          close={onCloseCategoryPopup}
          onClose={onCloseCategoryPopup}
          model_type={"responsive"}
          title={edit ? t("edit_shift_group_shift") : t("create_shift_group_shift")}
        >
          <CategoryManage
            onPopupClose={onCloseCategoryPopup}
            onSaveSuccess={onCgySave}
            mode={edit?"edit":"add"}
            params={edit?{category_id: cgySelected.cgy_id}:{}}
          />
        </Popup>
      )}

      {openShiftAssignedPopup && (
        <Popup
          open={openShiftAssignedPopup}
          close={onCloseShiftAssignedPopup}
          onClose={onCloseShiftAssignedPopup}
          model_type={"responsive"}
          title={t("shifts_not_assigned_shift")}
          maxWidth={"md"}
        >
          <NotAssignedPopup
            data={unassignedShiftList?.data?.data}
            onClick={onClickEditUnassignedList}
          />
        </Popup>
      )}
      <SyconfirmPopup
        close={setOpenNavConfirmPopup}
        open={openNavConfirmPopup}
        onConfirmClick={onConfirmNavItemDelete}
      />

      {openShiftPopup && (
        <Popup
          open={openShiftPopup}
          close={setOpenShiftPopup}
          onClose={onShiftPopupClose}
          model_type={"responsive"}
          maxWidth={shiftId ? 'lg' : 'sm'}
          title={shiftId ? t(`edit_shift_shift`) : t(`create_shift_shift`)}
        >
          <ShiftPopup
            mode={shiftId ? 'edit' : 'add'}
            schedulerView={schedulerType == 1 ? 'week' : 'month'}
            onPopupClose={onShiftPopupClose}
            params={shiftId ? getShiftPopupData(shiftId) : getShiftPopupData(null, defaultShiftValues)}
            onSaveSuccess={onShiftSaveSuccess}
          />
        </Popup>
      )}
      <div className={`${Stylesheet.container_fluid} container-fluid`}>
        <div className={`${Stylesheet.container} mb-2`}>
          <div className="d-flex align-items-center justify-content-between px-1 ps-2">
            <div className="d-flex align-items-center"></div>
            <div className=" text-right d-flex justify-content-end me-4 gap-3">
              {/* {userDetail?.type != "1" && (
                <Sybutton
                  type={"button"}
                  className={`primarybutton ${Stylesheet.plan}`}
                  size={"sm"}
                  value={"plan"}
                  label={t("export_plan_shift")}
                  onBtnClick={onExportButton}
                />
              )} */}
            </div>
          </div>
          {shift_admin && (
            <>
              <div className={`p-2 ${(scySelected?.scy_id || cgySelected?.cgy_id) ? "pb-1" : ""}`}>
                <DashboardNav
                  onClick={onNavClick}
                  cgyLoading={cgyLoading}
                  scyLoading={scyLoading}
                />
              </div>
              {(scySelected?.scy_id || cgySelected?.cgy_id) && (
                <div className="d-flex justify-content-end m-2">
                  <div className="d-flex justify-content-end align-items-center px-1 pb-1">
                    {/* <Sybutton
                      type={"button"}
                      className={`primarybutton me-2 p-1 ${Stylesheet.plan}`}
                      size={"sm"}
                      value={"plan"}
                      label={t("export_plan_shift")}
                      onBtnClick={onExportButton}
                    /> */}
                      {unassignedShiftList?.data?.data.length > 0 &&
                      <div className="d-flex me-2 ms-2">
                        <Sybutton
                          type={"button"}
                          className={`${Stylesheet.flagbutton} d-flex align-items-center text-white`}
                          size={"sm"}
                          icontype="outlined_flag"
                          value={width > 423 ? `${unassignedShiftList?.data.shiftNotAssignedCount} ${t("shifts_not_assigned_shift")}` : `${unassignedShiftList?.data?.shiftNotAssignedCount}`}
                          onBtnClick={onNotAssignedShift}
                          iconclass={Stylesheet.flagIcon}
                        />
                      </div>
                     } 
                    <SyMenu
                      menuBtn={menuIcon}
                      menuItems={NAV_MENU_CONFIG}
                      onClick={onMenuItemClick}
                      vertical={'bottom'}
                      horizontal={'right'}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <div className="mx-2">
            <SyDaywiseEventList
              source={shiftList}
              onHeaderBtnClick={onHeaderClick}
              headerButtonConfig={scySelected?.scy_id  && getHeaderButtons(!scySelected?.scy_id)}
              onCellClick={onCellClick}
              customHeaderElement={shiftViewDropdown}
              onClickEntity={onShiftEntityClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardScreen;