import React from "react";
import { Controller } from "react-hook-form";
import Stylesheet from "../style.module.scss";
import SyCollapse from "../../../../../../../shared/components/sy-collapse";

type Props = {
  mode: 'add' | 'edit';
  ss_repeat: boolean;
  control: any;
  ss_repeat_details: Record<string, any>;
  renderRepeatDetails: (r: any) => React.ReactElement;
  renderEndDate: (r: any) => React.ReactElement;
  renderWeekSheduler: (r: any) => React.ReactElement;
  renderRepeatCheckBox: (r: any) => React.ReactElement;
};

const RepeatField = ({
  mode,
  ss_repeat,
  control,
  ss_repeat_details,
  renderRepeatDetails,
  renderEndDate,
  renderWeekSheduler,
  renderRepeatCheckBox,
}: Props) => {
  return (
    <>
      <div className={`col-lg-2 d-flex align-items-center ${mode === 'add' ? 'ms-5' : 'ps-1'}`}>
        <Controller
          name="ss_repeat"
          control={control}
          render={renderRepeatCheckBox}
        />
      </div>
      <div className={`col-12`}>
        <SyCollapse isOpen={ss_repeat}>
          <div className="row mt-3 ">
            <div className="col-6">
              <Controller
                name="ss_repeat_details.sr_unit"
                control={control}
                render={renderRepeatDetails}
              />
            </div>
            <div className="col-6">
              <Controller
                name={"ss_repeat_end"}
                control={control}
                render={renderEndDate}
              />
            </div>
          </div>
        </SyCollapse>
        {ss_repeat_details?.id == "2" && (
          <div className={`pt-3 ${Stylesheet.weekPointer}`}>
            <Controller
              name={"ss_repeat_details.sr_day_of_week"}
              control={control}
              render={renderWeekSheduler}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RepeatField;
