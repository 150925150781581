import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: '',
  company_details:'',
  token:'',
  cy_themecolor:'',
  username:''
};

export const userDetailsSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    setTokenReducer:(state, { payload })=>{
        state.token = payload;
    },
    setUserDetailReducer: (state, { payload }) => {
      state.userDetails = payload;
    },
    setCompanyDetailReducer: (state, { payload }) => {
      state.company_details = payload;
    },
    setThemecolorReducer: (state, { payload }) => {
      state.cy_themecolor = payload;
    },
    setUserNameReducer: (state, { payload }) => {
      state.username = payload;
    },
  },
});

export const { setUserDetailReducer,setCompanyDetailReducer,setTokenReducer ,setThemecolorReducer,setUserNameReducer} = userDetailsSlice.actions;

export const getuserDetail = (state: any) => state.userDetailReducer.userDetails
export const getJwtToken = (state: any) => state.userDetailReducer.token
export const getCompanyDetails = (state: any) => state.userDetailReducer.company_details
export const getThemecolor = (state: any) => state.userDetailReducer.cy_themecolor
export const getUserName = (state: any) => state.userDetailReducer.username

export default userDetailsSlice.reducer;
