import { useState } from "react";
import SyChip from "../../../../../../shared/components/sy-chip";
import { weekPickerProps } from "./weekpicker-interface";
import { getWeekDaysWithId } from "./helper";
import Errormessage from "../../../../../../shared/components/forms/errormessage/errormessage";

const WeekPicker = ({ onChange, value, disabled = false, error }: weekPickerProps) => {
  const [weekActiveIds, setWeekActiveIds] = useState<any>(value ?? []);
  const weekDayList = getWeekDaysWithId();

  const onClickWeekDay = (val: any) => {
    if (!disabled) {
      const index = weekActiveIds.findIndex((id: any) => id === val);
      if (index !== -1) {
        const weekActive = [...weekActiveIds];
        weekActive.splice(index, 1);
        setWeekActiveIds(weekActive);
        onChange(weekActive);
      } else {
        const weekActive = [...weekActiveIds, val];
        setWeekActiveIds(weekActive);
        onChange(weekActive);
      }
    }
  };

  return (
    <>
    <div className="d-flex">
      {weekDayList.map((data: any) => (
        <div className="pe-1 cursor-pointer" key={data.id}>
          <SyChip
            label={data.day}
            color={`${weekActiveIds.includes(data.id) ? "	#6495ed" : "#dddddd"}`}
            type="rounded_circle"
            size="lg"
            value={data.id}
            onClickChip={onClickWeekDay}
          />
        </div>
      ))}
    </div>
    {error && (
        <span className="d-flex justify-content-end">
          <Errormessage message={error} />
        </span>
      ) }
    </>
  );
};

export default WeekPicker;
