import { memo, useMemo } from "react";
import { RenderDayListProps } from "../sy-calendar-model";
import RenderDaySlot from "./renderDaySlot";
import RenderEvents from "./renderEvents";
import RenderTimeSlot from "./renderTimeSlot";
import EventListItem from "./eventListItem";
import { sortEventsByStartDate } from "../helper";

/**
 * Renders a single day column in the calendar view.
 *
 * @param {Object} props.day - The data representing the day to be rendered.
 * @param {Array} props.times - The list of timeslots for the day.
 * @param {number} props.index - The index of the day in the calendar view.
 * @param {number} props.hourHeight - The height of each time slot (in pixels).
 * @param {string} props.calendarView - The current view mode for the calendar (e.g., weekly,monthly).
 * @param {Object} props.hoveredEventDetails - Details of the event currently hovered over.
 * @param {boolean} props.isSubCategoryClicked - Flag indicating if a subcategory is clicked.
 * @param {string} props.highlightedEventId - The ID of the event currently highlighted.
 * @param {function} props.onClickSlot - Callback for handling slot click event.
 * @param {function} props.onEventClick - Callback for handling event click event.
 * @param {function} props.onTooltipOpen - Callback for opening event tooltip.
 * @param {function} props.onTooltipClose - Callback for closing event tooltip.
 *
 */

const RenderDayList = (props: RenderDayListProps) => {
  const {
    day,
    times,
    hourHeight = 70,
    calendarView = 1,
    // hoveredEventDetails,
    showListView=false,
    onClickSlot = () => {},
    onEventClick = () => {},
    // onTooltipOpen = () => {},
    // onTooltipClose = () => {},
  } = props;

  /**
   * This memoized component renders the time slots for the given day, mapping over the `times` array.
   * The slots are rendered using the `RenderTimeSlot` component for each time in the `times` array.
   */
  const DayTimeSlot = memo(() => {
    const memoizedTimeSlots = useMemo(() => {
      return times.map((time: any, index: number) => (
        <RenderTimeSlot
          key={index}
          day={day}
          time={time}
          index={index}
          hourHeight={hourHeight}
          onClickTime={onClickSlot}
        />
      ));
    }, [times, day, hourHeight, onClickSlot]);
    return <>{ !showListView && memoizedTimeSlots}</>;
  });

  /**
   * This memoized component renders the events for the given day. It maps over the `day.events` array
   * and uses the `RenderEvents` component to render each event.
   */
  const DayEvents = memo(() => {
    const memoizedDayEvents = useMemo(() => {
      const sortedEvents = sortEventsByStartDate(day?.events);
      return sortedEvents?.map((event: any, index: number) => {
        return (
          <>
          {!showListView ? (<RenderEvents
            key={index}
            eventDetails={event}
            calendarView={calendarView}
            listView={showListView}
            // hoveredEventDetails={hoveredEventDetails}
            // onTooltipOpen={onTooltipOpen}
            // onTooltipClose={onTooltipClose}
            onEventClick={onEventClick}
          />) : (<EventListItem event={event}  calenderView={calendarView}/>)}
          </>
        );
      });
    }, [
      day.events,
      calendarView,
      // hoveredEventDetails,
      // onTooltipOpen,
      // onTooltipClose,
      onEventClick,
    ]);
    return <>{memoizedDayEvents}</>;
  });

  return (
    <>
      <div
        className="day-column calender-theme-color text-center"
        style={{
          width: `${day.width + 1}px`,
          height: `${showListView ? '' : hourHeight * 25}px`,
        }}
      >
        {/* Render the date slot */}
        <RenderDaySlot day={day} calendarView={calendarView} />

        {/* Render time slots for the day */}
        <DayTimeSlot />

        {/* Render events for the day  */}
        <DayEvents />
      </div>
    </>
  );
};
export default memo(RenderDayList);
