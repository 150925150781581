import { type_id_icon } from "../../../../../../shared/constants/constants";
import { SyIcons } from "../../../../../../shared/components";
import { getDateLocaleString } from "../../../../../../shared/utils/dayjs";
import { syDeepClone } from "../../../../../../shared/utils/helper";
import { dateRepeat } from "./config";
import { UseFormSetError } from "react-hook-form";

export const getIcon = (data: any) => {
  const icon = type_id_icon[data.type_id];
  return <SyIcons name={icon} iconStyle="" className={"me-3"} />;
};

export const getPayload = (formValues: Record<string, any>) => {
  const formData = syDeepClone(formValues);
  delete formData.check_repeat;
  formData.ss_title = formData.ss_title;
  formData.ss_start = getDateLocaleString(formData.ss_start, "dateTimeHour");
  formData.ss_end = getDateLocaleString(formData.ss_end, "dateTimeHour");
  formData.ss_status = "1";
  formData.ss_capacity = formData.ss_capacity || null;
  if (formData.ss_repeat) {
    formData.ss_repeat_details.sr_day_of_week = formData.ss_repeat_details?.sr_day_of_week || null;
    formData.ss_repeat_end = getDateLocaleString(formData.ss_repeat_end, "dateTimeHour");
  }
  formData.ss_repeat_details.sr_day_of_month = formData.ss_repeat_details?.sr_unit?.id === "3" ? getDateLocaleString(formData.ss_start, "setdate") : null;
  if (formData.ss_repeat_details?.sr_unit?.label) {
      formData.ss_repeat_details.sr_unit = formData.ss_repeat_details?.sr_unit?.label || null;
  }

  return {
    default: {
      ...formData,
      type: 3, /// this key is necessary for backend purpose
    },
  };
};

export const getWildcardDisplayName = (val: any) => val?.name;
export const getOptLabel = (data: any) => data?.scy_name;
export const getRepeatLabel = (data: any,t:any) => t(`${data?.label}_shift`);

export const getUserListPayload = (id: any, view: 'week' | 'month', date: any) => ({
  date: date,
  sub_category_id: id,
  sm_type: 2,
  type: view,
});

export const getUnitByLabel = (label: any) => {
  return dateRepeat.find((option: any) => option?.label === label);
}

const findIndexWithId = (id: number | string, from: Array<any>) => {
  return from.findIndex(
    (data: any) => data?.id == id
  );
}

const setErrorFor = (index: number, fieldName: string, setError: UseFormSetError<any>) => {
  if (index >= 0) {
    setError(`${fieldName}.[${index}]`, {
      type: "custom",
      message: "time_overlapping_shift",
    });
  }
}

export const setErrorForMember = (errorIds: Array<any>, memberFields: any, extraMemberFields: any, setError: UseFormSetError<any>) => {
  if (errorIds.length) {
    for (const id of errorIds) {
      const badMemberIndex = findIndexWithId(id, memberFields);
      const badExtraMemberIndex = findIndexWithId(id, extraMemberFields);
      setErrorFor(badMemberIndex, 'members', setError);
      setErrorFor(badExtraMemberIndex, 'extra_member', setError);
    }
  }
};

export const deleteUidIn = (arr: Array<any>) => {
  return arr.map((member: any) => {
    if (member.u_id) {
      member.id = member.u_id;
      delete member.u_id;
    }
    return member;
  });
};

export const getFilteredList = (list: Array<any> = [], filterList: Array<any> = [], key: string = 'id') => {
  filterList = filterList.map((val:any)=>Number(val?.[key]));
  return list.length ? list.filter((wildcard) => !filterList.includes(Number(wildcard.id))) : [];
}

export const addUidIn = ((items: Array<any>) => items.map((val:any)=>({...val, u_id: val.id})));
