import Stylesheet from "./style.module.scss";

import { useSelector } from "react-redux";

import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { getJwtToken } from "../../../../feature/common-slice";
import { ASSET_API_URL, MAIN_API_URL } from "../../../../../../shared/constants";

interface Props {
  data?: any;
  showName?: Boolean;
  type?: "single" | "group";
  max?: number;
  typeId?: string | number;
  id?: string | number;
  avatarSize?: "sm" | "md" | "lg" | "xl";
  fontSize?: "sm" | "md" | "lg" | 'xl';
}

const Avathar = ({
  data,
  showName = false,
  type = "single",
  max,
  typeId = "type_id",
  id = "id",
  avatarSize = "md",
  fontSize = "md",
}: Props) => {
  const token = useSelector(getJwtToken);

  const fontSizeMap: { [key: string]: string } = {
    sm: "0.8rem",
    md: "1rem",
    lg: "1.2rem",
    xl: "1.5rem",
  };

  /**
   *  function to generate the avatar image URL.
   */
  const getAvatarUrl = (data:any) =>{
    return  data[typeId] && data[id]
    ? `${ASSET_API_URL}avatar/${data[typeId]}/${data[id]}?token=${token}`
    : `${ASSET_API_URL}avatar/${data.master_type}/${data.user_id}?token=${token}`;
  }

  return (
    <>
      <div className="d-flex align-items-center ellipsisShift mb-2 mt-1">
        {type === "single" && (
          <div className={`${Stylesheet[avatarSize]} `}>
            <img
              src={getAvatarUrl(data)}
              className={`${Stylesheet.singleImage} user-select-none d-flex align-items-center`}
            />
          </div>
        )}

        {showName && (
          <p className={`ellipsisShift ms-2 mb-0 mt-0`} style={{fontSize:fontSizeMap[fontSize]}}>{data?.name}</p>
        )}
      </div>

      {type === "group" && (
        <div>
          <AvatarGroup max={max}>
            {data.map((data: any, index: number) => (
              <Avatar
                src={getAvatarUrl(data)}
                className={`${Stylesheet[avatarSize]} user-select-none d-flex align-items-center`}
                key={index}
              />
            ))}
          </AvatarGroup>
        </div>
      )}
    </>
  );
};
export default Avathar;
