import * as yup from "yup";


const extractTime = (dateString:any) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours * 60 + minutes;
};

export const shiftCreat = yup.object().shape({
  ss_title: yup.object().required("field_required_shift"),
  // ss_end: yup.object().required("field_required"),
  // ss_capacity: yup.string().required("field_required"),
  // members: yup
  //   .array()
  //   .nullable()
  //   .test(
  //     "check-capacity",
  //     "Number of members must match capacity",
  //     function (value: any) {
  //       const { ss_capacity }: any = this.parent;
  //       console.log(ss_capacity , 'capa');
        
  //       return value && value.length <= parseInt(ss_capacity);
  //     }
  //   ),

  ss_capacity: yup.string().required("field_required_shift"),  
  // members: yup.array()
  //   .nullable()
  //   .test(
  //     'check-capacity',
  //     'Number of members must match capacity',
  //     function (value) {
  //       const { ss_capacity } = this.parent;        
  //       if (ss_capacity === undefined || value === null) return true;
  //       return Array.isArray(value) && value.length <= parseInt(ss_capacity, 10);
  //     }
  //   ),

  // ss_start: yup.date().nullable().required("field_required").test("", "invalid_time_range", (value, context) => {
  //   const currentDate = new Date;
  //   if (currentDate && value) {
  //     return getDateLocaleString(value, "year") >= getDateLocaleString(currentDate, "year");
  //   }
  //   return true;
  // }),


  // ss_end: yup.string().required("field_required"),
  // ss_end: yup.string().trim().required("field_required"),
  // ss_start_time: yup
  //   .string()
  //   .nullable()
  //   .required("field_required")
  //   .test("start-in-future", "invalid-date", function (value: any, { parent }) {
  //     const ss_start = parent.ss_start;
  //     if (ss_start && new Date(ss_start) > new Date()) {
  //       return true;  
  //     }
  //     return new Date(value) > new Date(ss_start);
  //   }),

  // ss_end_time: yup
  //   .string()
  //   .required("field_required")
  //   .test("start-before-end", "invalid-date", function (value: any) {      
  //     const { ss_start_time } = this.parent;
  //     return new Date(ss_start_time) < new Date(value);
  //   })
  //   .test(
  //     "end-after-start",
  //     "End time must be after start time",
  //     function (value) {
  //       const { ss_start_time } = this.parent;
  //       const { ss_end_time } = this.parent;
  //       return new Date(ss_start_time) < new Date(ss_end_time);
  //     }
  //   ),

  ss_end_time: yup
  .string()
  .nullable()
  .required("field_required_shift")
  .test("start_before_end", "invalid_date_shift", function (value) {
    const { ss_start_time } = this.parent;
    return extractTime(ss_start_time) < extractTime(value);
  })
  .test("end-after-start", "End time must be after start time", function (value) {
    const { ss_start_time } = this.parent;
    return extractTime(ss_start_time) < extractTime(value);
  }),

  // ss_end: yup
  // .string()
  // .nullable()
  // .required("End date must be after start date")
  // .test("end_after_start", "End_date_must_be_after_start_date", function (value:any) {
  //   console.log(value,'oooooooooo')
  //   const { ss_start } = this.parent;
  //   // const {ss_repeat_end} = this.parent
  //   // Compare the date and time, assuming ss_start and ss_end are strings representing dates
  //   return new Date(value) >= new Date(ss_start);
  // })
  // // ss_end: yup.string().required("field_required"),
  // // ss_end: yup.string().trim().required("field_required"),

});

const memberSchema = yup.object({
  user: yup
    .object({
      name: yup.string().required("field_required"),
    })
    .nullable()
    .required("field_required"),
  alloted_time: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) || value === 0 ? null : value))
    .positive("dont_enter_a_negative_number")
    .required("field_required"),
});

const memberSchemaNotBound = yup.object({
  user: yup
    .object({
      name: yup.string().required("field_required"),
    })
    .nullable()
    .required("field_required"),
  alloted_time: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) || value === 0 ? null : value))
    .positive("dont_enter_a_negative_number"),
});

export const categorySchema = yup.object({
  cgy_name: yup.string().trim().required("field_required_shift"),
  cgy_desc: yup.string().nullable(),
  // cgy_status: yup
  //   .object({
  //     label: yup.string().required("field_required"),
  //   })
  //   .nullable()
  //   .required("field_required"),
  // members: yup
  //   .array()
  //   .when("cgy_time_bound", {
  //     is: true,
  //     then: (schema) => schema.of(memberSchema),
  //     otherwise: (schema) => schema.of(memberSchemaNotBound),
  //   })
  //   // .of(memberSchema)
  //   .min(1, "atleast_a_member_for_group_required"),
});

export const groupSchema = yup.object({
  scy_name: yup.string().required("field_required_shift"),
  scy_color: yup.string().nullable(),
  // sg_type:
  sg_desc: yup.string().nullable(),
  
  // sg_status: yup
  //   .object({
  //     value: yup.number().required("field_required"),
  //   })
  //   .nullable()
  //   .required("field_reqired"),
});


export const ShiftSchema = yup.object({
  ss_title: yup.string().required("field_required_shift"),
  ss_capacity: yup.lazy((value, context): any => {
    const { parent: { members, extra_member } } = context;
    const count = (members ?? []).length + (extra_member ?? []).length;

    let schema = yup
      .number()
      .nullable()
      .transform((value) => (Number.isNaN(value) || value === 0 ? null : value))
      .positive("dont_enter_a_negative_number");

    if (!value || value >= count) {
      return schema;
    } else {
      return schema.max(0, "capacity_too_low_shift");
    }
  }),
  ss_start: yup.string().nullable().required("field_required_shift"),
  ss_repeat_end:yup.string().nullable().test("end_validation", "invalid_date", function (value: any) {
    const { ss_start,ss_repeat} = this.parent;
    return (ss_repeat ? new Date(value) >= new Date(ss_start): true) 
  }),
  ss_repeat_details: yup.object().nullable().shape({
    sr_unit: yup.object().nullable().notRequired(),
    sr_day_of_week: yup.array().when('sr_unit',{
      is : (val:any) => {
        return val?.id == 2;
      },
      then: yup.array().min(1, "field_required_shift"),
      otherwise: yup.array().notRequired()
    })}),
  ss_end: yup
    .string()
    .required("field_required_shift")
    .test("end_validation", "end_date_must_be_after_start_date_shift", function (value: any) {
      const { ss_start } = this.parent;
      return new Date(value) >= new Date(ss_start);
    }),
});
