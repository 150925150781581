import { Controller, useFieldArray, useForm } from "react-hook-form";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import { useTranslation } from "react-i18next";
import { shiftProps } from "./shift-interface";
import SyDateTimepicker from "../../../../../../shared/components/forms/sy-date-time-picker";
import { InputForm, SyToggleButton } from "../../../../../../shared/components/forms";
import { dateRepeat, wildcard } from "./config";
import Stylesheet from "./style.module.scss";
import WeekPicker from "../../components/weekpicker";
import DatePicker from "../../../../../../shared/components/forms/date-picker";
import SyTextEditor from "../../../../../../shared/components/forms/sy-rich-text-edit";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import shiftService from "../../service/shift-service";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import { addTime, getDateWithCurrentTime} from "../../../../../../shared/utils/dayjs";
import { addUidIn, deleteUidIn, getFilteredList, getIcon, getOptLabel, getPayload, getRepeatLabel, getUnitByLabel, getUserListPayload, getWildcardDisplayName, setErrorForMember } from "./helper";
import MemberList from "./components/member-list";
import RepeatField from "./components/repeat-field";
import { yupResolver } from "@hookform/resolvers/yup";
import { ShiftSchema } from "../../components/schema-validation/schema-validation";
import SyCollapse from "../../../../../../shared/components/sy-collapse";
import Popup from "../../../../../../shared/components/sy-model";
import WholeSeries from "../whole-series-popup/whole-series-popup";

const ShiftPopup = (props: shiftProps) => {
  const { onPopupClose, params, schedulerView, mode, onSaveSuccess = () => {} } = props;
  const { shift_id: shiftId, cgy_id: cgyId, scy_id: scyId, default_date: defaultDate, scheduler_date: schedulerDate, user, plan } = params || {};
  const { t } = useTranslation();

  //states
  const [deletableEmIndex, setDeletableEmIndex] = useState<any>();
  const [deletableMemIndex, setDeletableMemIndex] = useState<any>();
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [openWholeSeriesPopup, setOpenWholeSeriesPopup] = useState<boolean>(false);

  const currentTime = defaultDate ? getDateWithCurrentTime(defaultDate) : new Date();
  const defaultFormValues = {
    ss_scy_id: scyId,
    ss_title: '',
    ss_start: currentTime,
    ss_end: addTime(currentTime, 1, "hour"),
    ss_repeat_details: {
      sr_unit: dateRepeat[0],
      sr_day_of_week: [],
      sr_day_of_month: null,
    },
    ss_capacity: mode === "edit" ? 0 : 1,
    ss_repeat: false,
    quick_employee: false,
    ss_description: null,
    ss_whole_series: true,
    ss_repeat_end: null,
    members: (user && !!user.id) ? [{...user, u_id: user.id}] : [],
  };

  const {
    control,
    getValues,
    setValue,
    register,
    watch,
    handleSubmit,
    setError,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(ShiftSchema),
    mode: "all",
  });

  const formErrors: any = errors;

  const {
    fields: memberFields,
    append: addMember,
    remove: deleteMember,
  } = useFieldArray({
    control: control,
    name: "members",
  });

  const {
    fields: extraMemberFields,
    append: addExtraMember,
    remove: deleteExtraMember,
  } = useFieldArray({
    control: control,
    name: "extra_member",
  });


  // MUTATIONS //////
  // const { mutate: getGroupList, data: groupList } = useMutation({
  //   mutationFn: shiftService.getShiftGroup,
  //   onSuccess(data: any) {
  //     const existingShiftObj = data?.data.find(
  //       (item: any) => item?.scy_id === scyId
  //     );
  //     mode === "add" && setValue("ss_title", existingShiftObj, { shouldValidate: true });
  //   },
  // });

  const { mutate: deleteShift } = useMutation({
    mutationFn: shiftService.deleteShift,
    onSuccess(data:any) {
      const message = data.data.message;
      onSaveSuccess();
      SyToast(t(message),"top-right", "short", "success");
      onPopupClose(false);
    },
  });

  const { data: wildcardData, mutate: getWildcardData, isLoading: extraMemberLoading } = useMutation({
    mutationFn: shiftService.getwildCardShift,
  });

  const {
    data: memberList,
    mutate: getMemberList,
    isLoading: memberLoading,
  } = useMutation({
    mutationFn: shiftService.getUserList,
  });

  const { mutate: saveShift ,isLoading: shiftLoading } = useMutation({
    mutationFn: shiftService.saveShift,
    onSuccess(data: any) {
      SyToast(t(data.data.message), "top-right", "short", "success");
      onSaveSuccess();
      onPopupClose(false);
    },
    onError(error: any) {
      SyToast(t(error.response?.data?.message) || t("an_error_occurred_while_saving_the_shift"), "top-right", "short", "error");
      setErrorForMember(error?.response?.data?.userIds || [], getValues('members'), getValues('extra_member'), setError);
    },
  });

  //alotted time validation api
  const { mutate: validateMember } = useMutation({
    mutationFn: shiftService.validateMember,
    onSuccess(data: any) {
      clearErrors();
    },
    onError(error: any) {
      SyToast(t(error.response.data.message), "top-right", "short", "error");
      setErrorForMember(error?.response?.data?.userIds || [], memberFields, extraMemberFields, setError);
    },
  });

  const { mutate: getShiftDetails } = useMutation({
    mutationFn: shiftService.getShift,
    onSuccess(data: any) {
      setFormValues(data.data.default);
      data.data?.default?.ss_scy_id && getMemberList(getUserListPayload(data.data.default.ss_scy_id, schedulerView, schedulerDate));
    },
  });

  const setFormValues = (val: any) => {
    setValue("check_repeat", val?.ss_repeat, { shouldValidate: true });
    setValue("ss_scy_id", val?.ss_scy_id, { shouldValidate: true });
    setValue("ss_title", val?.ss_title, { shouldValidate: true });
    setValue("ss_start", val.ss_start, { shouldValidate: true });
    setValue("ss_end", val.ss_end, { shouldValidate: true });
    setValue("members", val?.members.length? addUidIn(val.members) : [], { shouldValidate: true });
    setValue("ss_capacity", val.ss_capacity? val.ss_capacity : undefined, { shouldValidate: true });
    setValue("ss_description", val.ss_description, { shouldValidate: true });
    setValue("ss_repeat", val.ss_repeat, { shouldValidate: true });
    setValue("ss_repeat_end", val.ss_repeat_end, { shouldValidate: true });
    setValue("ss_repeat_details.sr_unit", getUnitByLabel(val.sr_repeat_details?.sr_unit), { shouldValidate: true });
    if (val.sr_repeat_details?.sr_detail?.sr_day_of_week.length) {
      setValue("ss_repeat_details.sr_day_of_week", val.sr_repeat_details.sr_detail.sr_day_of_week, { shouldValidate: true });
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      const payload = {
        type: schedulerView,
      };
      getShiftDetails({ editShiftId: shiftId, payload });
    }
    // cgyId && getGroupList({id: cgyId});
    (scyId || plan?.scy_id) && getMemberList(getUserListPayload(plan?.scy_id ?? scyId, schedulerView, schedulerDate));
  }, []);

  //watching the specific form changes
  const { ss_repeat, ss_repeat_details, quick_employee, ss_repeat_end, ss_whole_series } = useMemo(() => {
    const [ss_repeat, ss_repeat_details, quick_employee, ss_repeat_end, ss_whole_series] = getValues(["ss_repeat", "ss_repeat_details.sr_unit", "quick_employee", 'ss_repeat_end', "ss_whole_series"]);
    return { ss_repeat, ss_repeat_details, quick_employee, ss_repeat_end, ss_whole_series }
  }, [watch(["ss_repeat", "ss_repeat_details.sr_unit", "quick_employee", "ss_repeat_end", "ss_whole_series"])]);

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++ RENDER-FIELDS-START ++++++++++++++++++++++++++++++++++++++++++++
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //title
  const renderTitle = ({ field: { onChange, value } }: any) => {
    return (
      <InputForm
      type={"text"}
      placeholder={t("title_shift")}
      onInputChange={onChange}
      value={value}
      error={errors.ss_title?.message}
      {...{autoFocus:true}}
    />
    );
  };

  const validateMembers = (val: any) => {
    //future use
    // validateMember(val);
  };

  //start_date
  const renderStartDatetime = ({ field: { onChange, value } }: any) => {
    const onValChange = (val: any) => {
      onChange(val);
      setValue("ss_end", addTime(val, 1, "hour"), { shouldValidate: true })
      if (ss_repeat && ss_repeat_end < val) {
        // incase of start date being greater than repeat end date
        setValue("ss_repeat_end", addTime(val, 1, "month"), { shouldValidate: true });
      }
      if (memberFields.length || extraMemberFields.length) {
        validateMembers(getPayload(getValues()));
      }
      trigger('ss_end');
    };
    return (
      <SyDateTimepicker
        placeholder={t("start_date_shift")}
        value={value}
        onChange={onValChange}
        format="fullDateTime"
      />
    );
  };

  //end_date
  const renderEndDatetime = ({ field: { onChange, value } }: any) => {
    const onEndDateChange = (val: any) => {
      onChange(val);
      validateMembers(getPayload(getValues()));
    };
    return (
      <SyDateTimepicker
      placeholder={t("end_date_shift")}
      value={value}
      onChange={onEndDateChange}
      minVal={getValues("ss_start") || new Date()}
      errorMessage={formErrors.ss_end?.message}
       format="fullDateTime"
      />
    );
  };

  //repeat_check
  const renderRepeatCheckBox = ({ field: { onChange, value } }: any) => {
    const handleCheckboxChange = (val: any) => {
      onChange(val.target.checked);
      if (val.target.checked === true) {
        setValue("ss_repeat_details.sr_unit", dateRepeat[0], { shouldValidate: true });
        const repeatEndDate = addTime(getValues("ss_start"), 1, "month");
        setValue("ss_repeat_end", repeatEndDate, { shouldValidate: true });
      } else {
        setValue("ss_repeat_details.sr_unit", null, { shouldValidate: true });
        setValue("ss_repeat_end", null, { shouldValidate: true });
      }
    };
    return (
      <InputForm
        type={"checkbox"}
        placeholder={t("repeat_shift")}
        onChange={handleCheckboxChange}
        defaultChecked={value}
        disable={!ss_whole_series}
      />
    );
  };

  //repeat_details
  const renderRepeatDetails = ({ field: { onChange, value } }: any) => {
    const onRepeatDetailsChange = (val: any) => {
      onChange(val);
      if ((memberFields.length || extraMemberFields.length) && val.id !== "2") {
        validateMembers(getPayload(getValues()));
      }
      setValue("ss_repeat_end", addTime(getValues('ss_start'), 1, val.id === "3" ? "year" : "month"), { shouldValidate: true });
    };

    const optionsLabel = (value: string) => {
      return getRepeatLabel(value, t);
    };

    return (
      <Dropdown
        options={dateRepeat}
        placeholder={t("repeat_every_shift")}
        controlledVal={value || null}
        handleChange={onRepeatDetailsChange}
        getoptlabel={optionsLabel}
        clearIcon={true}
        disabled={!ss_whole_series}
      />
    );
  };

  const renderWeekSheduler = ({ field: { onChange, value } }: any) => {
    const onChangeWeek = (val: any) => {
      onChange(val);
      if ((memberFields.length || extraMemberFields.length) && val.length) {
        validateMembers(getPayload(getValues()));
      }
    };
    return <WeekPicker value={value} onChange={onChangeWeek} disabled={!ss_whole_series} error={formErrors.ss_repeat_details?.sr_day_of_week?.message}/>;
  };

  //repeat_endDate
  const renderEndDate = ({ field: { onChange, value } }: any) => {
    return (
      <DatePicker
        Placeholder={t("end_date_shift")}
        date={value}
        format="date"
        selectedDate={onChange}
        disabled={!ss_whole_series}
        errorMessage={errors.ss_repeat_end?.message}
      />
    );
  };

  //quick_emp
  const renderQuickEmployee = ({ field: { onChange, value } }: any) => {
    const handleToggleClick = (e: any) => {
      if(!e.target.checked){
        setValue("extra_member", [])
        triggerCapacityValidation();
      }
      onChange(e);
      e.target.checked && getWildcardData(wildcard);
    };
    return (
      <SyToggleButton
        id={"extra_member"}
        value={value}
        toggleClick={handleToggleClick}
      />
    );
  };

  const renderWholeSeries = ({ field: { onChange, value } }: any) => {
    return (
      <SyToggleButton
        id={"whole_series"}
        value={value}
        toggleClick={onChange}
      />
    );
  };

  //description
  const renderDescription = ({ field: { onChange, value } }: any) => {
    return (
      <SyTextEditor
        value={value}
        onChange={onChange}
        register={register("ss_description")}
        name={"ss_description"}
        placeholder={t("description_shift")}
      />
    );
  };

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++ RENDER-FIELDS-END ++++++++++++++++++++++++++++++++++++++++++++++
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

   /**
   * Handles the toggling of the whole series popup.
   * @param {string} type - The choice ("yes" or "no") for whole series shift.
   */
  const changeWholeSeries = (type: any) => {
    setOpenWholeSeriesPopup(false);
    if (type === "yes") {
      setValue("ss_repeat", true, { shouldValidate: true });
      setValue("ss_whole_series", true, { shouldValidate: true });
    } else if (type === "no") {
      setValue("ss_repeat", false, { shouldValidate: true });
      setValue("ss_whole_series", false, { shouldValidate: true });
    }
    saveShift({
      editShiftId:  shiftId , // If it's an edit mode, pass the shiftId
      payload: getPayload(getValues()), // Get the final payload after the update
    });
  };

  //onSumbit result
  const onSubmit = (formValues: any) => {
    if (formValues?.members && formValues.members.length) {
      formValues.members = deleteUidIn(formValues.members);
    }

    if (formValues?.extra_member && formValues.extra_member.length) {
      formValues.extra_member = deleteUidIn(formValues.extra_member);
    }
    if (formValues.ss_repeat && mode === "edit" && getValues("check_repeat") ) {
      setOpenWholeSeriesPopup(true);
    } else {
      saveShift({
        editShiftId: mode === "edit" ? shiftId : undefined,
        payload: getPayload(formValues),
      });
    }
    
  };

  const appendSelectedMember = (val: any, appendFunc: any) => {
    val.u_id = val.id;
    appendFunc(val);
  }

  const triggerCapacityValidation = () => {
    trigger("ss_capacity");
  };

  const onExtraMemberSelect = (val: any) => {
    val && appendSelectedMember(val, addExtraMember);
    triggerCapacityValidation();
  };

  const onExtraMemberDelete = (val: any) => {
    setDeletableEmIndex(val.index);
  };

  const onMemberDelete = (val: any) => {
    setDeletableMemIndex(val.index);
  };

  const onConfirmEmDelete = () => {
    deleteExtraMember(Number(deletableEmIndex));
    setDeletableEmIndex(false);
    triggerCapacityValidation();
  };

  const onConfirmMemDelete = () => {
    deleteMember(Number(deletableMemIndex));
    setDeletableMemIndex(false);
    triggerCapacityValidation();
  };

  const onMemberSelect = (val: Record<string, any>) => {
    val && appendSelectedMember(val, addMember);
    triggerCapacityValidation();
  };

  const onClickDelete = (val: any) => {
    setOpenDeletePopup(true);
  };
  const onConfirmShiftDelete = () => {
    deleteShift(shiftId);
  };

  const filteredMemberList = useMemo(() => {
    return getFilteredList(memberList?.data ?? [], memberFields, "u_id");
  }, [memberList?.data, memberFields]) ?? [];

  const filteredExtraMemberList = useMemo(() => {
    return getFilteredList(wildcardData?.data, [...(memberList?.data ?? []), ...deleteUidIn(extraMemberFields)]);
  }, [wildcardData?.data, memberList?.data, extraMemberFields]) ?? [];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={mode === "edit" ? `row ${Stylesheet.popup_style}` : ""}>
        <div className={mode === "edit" ? `col-6 border-end ` : ""}>
          <div className={`mb-3`}>
            <Controller
              name="ss_title"
              control={control}
              render={renderTitle}
            />
          </div>

          <div className="row">
            <div className={`col-xm-12 col-sm-6 mb-3`}>
              <Controller
                name={"ss_start"}
                control={control}
                render={renderStartDatetime}
              />
            </div>
            <div className={`col-xm-12 col-sm-6 mb-3`}>
              <Controller
                name={"ss_end"}
                control={control}
                render={renderEndDatetime}
              />
            </div>
          </div>

          <div className="row  mb-2">
            <div className="col-12 me-2 d-flex flex-wrap">
              <div className="col-lg-6 me-2">
                <Controller
                  name="ss_capacity"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputForm
                      placeholder={t("capacity_shift")}
                      type="number"
                      value={value}
                      onInputChange={onChange}
                      error={errors.ss_capacity?.message}
                    />
                  )}
                />
              </div>
              {mode === "add" ? (
                <RepeatField
                  mode={mode}
                  ss_repeat={ss_repeat}
                  control={control}
                  ss_repeat_details={ss_repeat_details}
                  renderRepeatDetails={renderRepeatDetails}
                  renderEndDate={renderEndDate}
                  renderWeekSheduler={renderWeekSheduler}
                  renderRepeatCheckBox={renderRepeatCheckBox}
                />
              ) : null}
            </div>
          </div>
          <div className={`mt-3 row`}>
            <Dropdown
              options={memberList?.data ? filteredMemberList : []}
              loading={memberLoading}
              controlledVal={null}
              placeholder={t("select_employees_shift")}
              blurOnSelect={true}
              handleChange={onMemberSelect}
              getoptlabel={getWildcardDisplayName}
              getIcon={getIcon}
            />
            <div className="pt-3">
              {memberFields.map((data: any, index: number) => (
                <div key={data.id}>
                  <MemberList
                    data={{ ...data, index }}
                    onClickDelete={onMemberDelete}
                    displayId="u_id"
                    errorMessage={formErrors?.members?.[index] && formErrors?.members?.[index].message}
                  />
                </div>
              ))}
            </div>
            {(deletableMemIndex >= 0) && (
              <SyconfirmPopup
                onConfirmClick={onConfirmMemDelete}
                open={typeof deletableMemIndex === "number"}
                close={setDeletableMemIndex}
              />
            )}
          </div>
          <div className={`d-flex`}>
            <Controller
              name="quick_employee"
              control={control}
              render={renderQuickEmployee}
            />
            <label htmlFor="extra_member_toggle" className="ml-5">
              {t("quick_employee_shift")}
            </label>
          </div>
          <SyCollapse isOpen={quick_employee}>
            <div className={`${Stylesheet.member_dd_style} pt-2`}>
              <Dropdown
                options={wildcardData?.data ? filteredExtraMemberList : []}
                loading={extraMemberLoading}
                controlledVal={null}
                placeholder={t("quick_member_shift")}
                blurOnSelect={true}
                handleChange={onExtraMemberSelect}
                getoptlabel={getWildcardDisplayName}
                getIcon={getIcon}
              />
              <div className="pt-3">
                {extraMemberFields.map((data: any, index: number) => (
                  <MemberList
                    displayId="id"
                    key={data?.id}
                    data={{ ...data, index }}
                    onClickDelete={onExtraMemberDelete}
                    errorMessage={formErrors?.extra_member?.[index] && formErrors?.extra_member?.[index].message}

                  />
                ))}
              </div>
              {(deletableEmIndex >= 0) && (
                <SyconfirmPopup
                  onConfirmClick={onConfirmEmDelete}
                  open={typeof deletableEmIndex === "number"}
                  close={setDeletableEmIndex}
                />
              )}
            </div>
          </SyCollapse>
          {/* {mode === "edit" ? (
            <div className={`d-flex mt-1`}>
              <Controller
                name="ss_whole_series"
                control={control}
                render={renderWholeSeries}
              />
              <label htmlFor="whole_series_toggle" className="ml-5">
                {t("whole_series_shift")}
              </label>
            </div>
          ) : (
            void 0
          )} */}
        </div>

        <div className={mode === "edit" ? `col-6` : ""}>
          <div className="row  mb-2">
            <div className="col-12 me-2 d-flex flex-wrap">
              {mode === "edit" ? (
                <RepeatField
                  mode={mode}
                  ss_repeat={ss_repeat}
                  control={control}
                  ss_repeat_details={ss_repeat_details}
                  renderRepeatDetails={renderRepeatDetails}
                  renderEndDate={renderEndDate}
                  renderWeekSheduler={renderWeekSheduler}
                  renderRepeatCheckBox={renderRepeatCheckBox}
                />
              ) : null}
            </div>
          </div>

          <Controller
            name={"ss_description"}
            control={control}
            render={renderDescription}
          />

          <div className="d-flex justify-content-end mt-3">
            {mode === "edit" ? (
              <Sybutton
                type={"button"}
                className={"primarybutton me-2"}
                size={"sm"}
                label={t("delete_shift")}
                value={shiftId}
                onBtnClick={onClickDelete}
              />
            ) : null}

            <Sybutton
              type={"button"}
              className={"Secondarybutton me-2"}
              size={"sm"}
              label={t("cancel_shift")}
              value={false}
              onBtnClick={onPopupClose}
            />
            <Sybutton
              type={"submit"}
              Loadings={shiftLoading}
              className={"primarybutton"}
              size={"sm"}
              label={t("save_shift")}
            />
          </div>
        </div>
      </div>
      {openWholeSeriesPopup && (
        <Popup
          title={t("confirmation")}
          open={openWholeSeriesPopup}
          close={setOpenWholeSeriesPopup}
          model_type={"responsive"}
          maxWidth={"xs"}
        >
          <WholeSeries onConfirmAction={changeWholeSeries}/>
        </Popup>
      )}
      {openDeletePopup ? (
        <SyconfirmPopup
          onConfirmClick={onConfirmShiftDelete}
          open={openDeletePopup}
          close={setOpenDeletePopup}
        />
      ) : null}
    </form>
  );
};

export default ShiftPopup;
