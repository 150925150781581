import http  from "../../../../../axios";
import { payload } from "../../../../simpler/modules/simpler-module/components/task-list/task-list-config";

class getservice {
  saveShiftCategory(data: any) {
    return http("shift_url").post(`category/save${data.id ? `/` +data.id : '' }`, data.payload);
  }

  deleteShiftCategory(id: any) {
    return http("shift_url").delete(`category/delete/${id}`);
  }

  getwildCard(data: any) {
    return http().post(
      `/public/support/wildcard${data.searchValue ? '/' +data.searchValue : '' }`,
      data.payload
    );
  }

  getwildCardShift(payload:any) {
    return http().post(
      `/public/support/wildcard`,
     payload
    );
  }

  getCategoryGroup(mode?: string) {
    return http("shift_url").get(`/category/list`);
  }

  getSubCategoryValidation(data: any) {
    const {id,payload} = data || {}
    return http("shift_url").post(`/sub_category/validate/${id}`, payload);
  }

  getShiftGroup(data: any) {
    return http("shift_url").get(`sub_category/get_sub_category/${data.id}`);
  }

  getShiftEditCategory(id: any) {
    return http("shift_url").get(`category/get/${id}`);
  }

  shiftGroupSave(data: any) {
    return http("shift_url").post(`sub_category/save${data.id ? '/' +data.id : '' }`, data.payload);
  }

  deleteShiftGroup(id: any) {
    return http("shift_url").delete(`sub_category/delete/${id}`);
  }

  getSubCategoryData(id: any) {
    return http("shift_url").get(`sub_category/get/${id}`);
  }
  saveShift(data: any) {
    return http("shift_url").post(`shifts/save${data.editShiftId?'/'+data.editShiftId:''}`, data.payload);
  }

  deleteShift(id: any) {
    return http("shift_url").delete(`shifts/delete/${id}`);
  }

  getGroups() {
    return http("shift_url").get(`/sub_category/list`);
  }

  getcategorygroups(id:any) {
    return http("shift_url").get(`/shifts/getcategorygroups/${id}`);
  }

  getShift(data:any) {
    return http("shift_url").post(`/shifts/get/${data.editShiftId}`,data.payload);
  }

  getAvailableList({payload, signal}:any) {
    return http("shift_url").post(`/shifts/get_members_shift_day_wise`,payload, {signal});
  }

  getUserList(payload:any) {
    return http("shift_url").post(`shifts/members_list`,payload);
  }

  getShiftList(data:any) {
    const { payload, signal } = data;
    return http("shift_url").post(`shifts/list`,payload, {signal});
  }

  getExportList(payload:any){
    return http("shift_url").post(`excel/export/shifts`,payload);
  }

  getShiftsForMember({payload, signal}:any){
    return http("shift_url").post(`shifts/myshifts`,payload,{signal});
  }

  getCategoryMemberList({payload, signal}:any){
    return http("shift_url").post(`shifts/get_category_members_shift`,payload, {signal})
  }

  validateMember(payload:any){
    return http("shift_url").post(`shifts/validate_members` ,payload);
  }
  
  getShiftAssignedData({payload, signal}:any){
    return http("shift_url").post(`shifts/shift_not_assigned`,payload, {signal})
  }

}

export default new getservice();
