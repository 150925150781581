import { useTranslation } from "react-i18next";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import { wholeSeriesPopup } from "./whole-series-model";
import { wholeSeriesPopupButtonConfig } from "./config";

const WholeSeries = (props: wholeSeriesPopup) => {
  const { onConfirmAction } = props;
  const { t } = useTranslation();

  const onClick = (val: any) => {
    onConfirmAction(val);
  };
  return (
    <>
      <div className={`d-grid align-items-center justify-content-center`}>
        <span>{t("do_you_want_to_change_whole_series_shift")}</span>
      </div>
      <div className="d-flex pt-4 justify-content-end">
        {wholeSeriesPopupButtonConfig.map((val, index) => {
          return (
            <>
              <Sybutton
                key={index}
                type={"button"}
                className={val.style}
                size={"sm"}
                label={t(val.value)}
                value={val.value}
                onBtnClick={onClick}
              />
            </>
          );
        })}
      </div>
    </>
  );
};
export default WholeSeries;
