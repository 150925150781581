import EventCard from "./eventCard";
import SyHtmlTooltip from "../../sy-html-tooltip";
import SyChip from "../../sy-chip";
import { EventListItemProps } from "../sy-calendar-model";

const EventListItem = (props: EventListItemProps) => {
  const { event, calenderView } = props;

  return (
    <>
      {calenderView == 1 ? (
        <div className="py-1 px-2">
          <EventCard data={event} detailedView={false} />
        </div>
      ) : (
        <SyHtmlTooltip
          htmlElement={<EventCard data={event} detailedView={false} />}
          children={
            <span>
              <SyChip
                className={"mb-1 p-3"}
                label={" "}
                size="md"
                color={event?.color}
              />
            </span>
          }
        />
      )}
    </>
  );
};
export default EventListItem;
