import { ClickAwayListener, Fade, Tooltip } from "@mui/material";

type Props = {
  htmlElement: any;
  children: any;
  open?: boolean;
  disableHover?: boolean;
  disableFocus?: boolean;
  disableInteractive?: boolean;
  disableTouch?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onClickAway?: () => void;
  onBlur?:any;
  onMouseLeave?:any
};

const SyHtmlTooltip = (props: Props) => {
  const {
    htmlElement,
    children,
    onClose,
    onOpen,
    onClickAway = () => void null,
    open,
    disableFocus,
    disableHover,
    disableInteractive,
    disableTouch,
    onBlur,
    onMouseLeave
    } = props;


  const componentsProps = {
    tooltip: {
      sx: {
        boxShadow: "3px 3px 3px 3px lightgrey",
        padding:'0',
        bgcolor: "white",
        opacity: 0.5,
        color: "black",
        "& .MuiTooltip-arrow": {
          color: "white",
        },
      },
    },
  };



  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <Tooltip
          children={children}
          title={htmlElement}
          disableHoverListener={disableHover}
          disableFocusListener={disableFocus}
          disableInteractive={disableInteractive}
          disableTouchListener={disableTouch}
          enterDelay={100}
          leaveDelay={100}
          onBlur={onBlur}
          TransitionComponent={Fade}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          componentsProps={componentsProps}
          arrow={true}
          onMouseLeave={onMouseLeave}
        />
      </ClickAwayListener>
    </>
  );
};

export default SyHtmlTooltip;
