/**
 * Creates a new AbortSignal. If an existing AbortController is present, it will be aborted before creating a new one.
 * This function ensures that the previous signal is canceled and a fresh signal is returned, which can be used for
 * aborting asynchronous operations like `fetch`.
 *
 * @function createAbortSignal
 * @returns {AbortSignal} The AbortSignal associated with the newly created AbortController.
 *
 */
export const createAbortSignal = () => {
  let controller: any = null;

  return ()=>{
    if (controller) {
      controller?.abort();
    }
    controller = new AbortController();
    const signal = controller.signal;

    return signal;
  }
};
