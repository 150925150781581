//react hooks
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//styles
import styleSheet from "./style.module.scss";
//service
import shiftService from "../../service/shift-service";
//redux-slice
import {
  upsertCategory,
} from "../../../../feature/shift-slice";
//forms
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//components
import {
  InputForm,
} from "../../../../../../shared/components/forms";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
//interfaces
import {
  ShiftCategoryProps,
  categoryFormValues,
} from "./shift-category-interface";
//validation
import { categorySchema } from "../../components/schema-validation/schema-validation";
//helpers
import { getShiftDashBoardParamPayload, setQueryParams } from "../../../../../../shared/utils/helper";
import { generatePayload } from "./helper";
import { displayOverlayLoading } from "../../../../feature/common-slice";

const CategoryManage = (props: ShiftCategoryProps) => {
  //props
  const { onPopupClose, onSaveSuccess = () => { }, mode , params } = props;
  const {category_id : categoryId} = params || {}

  //dispatch
  const dispatch = useDispatch();
  //translation
  const { t } = useTranslation();
  //default values in form
  const formDefaultValues = {
    cgy_name: "",
  };

  //useForm
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<categoryFormValues>({
    mode: "all",
    defaultValues: formDefaultValues,
    shouldUnregister: true,
    resolver: yupResolver<any>(categorySchema),
  });

  const { mutate: getShiftDetail, isLoading: isLoading } = useMutation({
    mutationFn: shiftService.getShiftEditCategory,
    onSuccess(data: any) {
      setFormValues(data.data);
    },
  });

  //sideeffect
  useEffect(() => {
    if(mode === 'edit'){
      getShiftDetail(categoryId);
    }
  }, []);

  useEffect(() => {
      dispatch(displayOverlayLoading(isLoading ? 1 : 0));    
  }, [isLoading]);

  const setFormValues = (value: any) => {
    setValue("cgy_name", value.cgy_name);
  };

  const { mutate: saveMutation, isLoading: saveLoading } = useMutation({
    mutationFn: shiftService.saveShiftCategory,
    onSuccess(data, variables) {
      const cgySelect = {
        cgy_name: variables.payload.default.cgy_name,
        cgy_id: variables.id ?? data?.data?.id,
      };
      dispatch(upsertCategory(cgySelect));
      onSaveSuccess(cgySelect);
      setQueryParams(getShiftDashBoardParamPayload(`cgy=${data?.data?.id || variables.id}`));
      SyToast(t(`${data.data.message}`), "top-right", "short", "success");
      onPopupClose();
    },
    onError(error: any) {
      SyToast(t(error.response?.data?.message || `something_went_wrong_shift`), "top-right", "short", "error");
      onPopupClose();
    }
  });

  const save = (saveData: any) => {
    const payload = generatePayload(saveData);
    saveMutation({ id: mode === "edit" ? categoryId : null, payload });
  };

  const handleFormSubmit = (data: any) => {
    save(data);
  };

 return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className={`${errors?.cgy_name?.message ? "lh-1" : "pb-3"} mb-3`}>
          <Controller
            name="cgy_name"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => {
              const handleInputChange = (event: any) => {
                onChange(event.target.value);
              };
              return (
                <InputForm
                  onBlur={onBlur}
                  type={"text"}
                  onInputChange={handleInputChange}
                  placeholder={t("name_shift")}
                  value={value}
                  error={errors?.cgy_name?.message}
                  {...{autoFocus:true}}
                />
              );
            }}
          />
        </div>

        <div className={`d-flex justify-content-end mt-1 ${styleSheet.grpBtn}`}>
          <div>
            <Sybutton
              className={`Secondarybutton me-2 ${styleSheet.plan}`}
              label={t("close_shift")}
              type={"reset"}
              onBtnClick={onPopupClose}
              size={"sm"}
            />
          </div>
          <Sybutton
            className={`primarybutton  me-2 ${styleSheet.plan}`}
            label={t("save_shift")}
            type={"submit"}
            size={"sm"}
            Loadings={saveLoading}
          />
        </div>
      </form>
    </>
  );
};

export default CategoryManage;
