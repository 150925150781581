import Stylesheet from "./style.module.scss";
import sodisys_logo from "./sodisys_logo.png";
import { getCookie } from "../../../../../shared/utils";
import { useContext, useState } from "react";
import SyIcons from "../../../../../shared/components/sy-icons";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../context";
import config from "../../../../../environments/env-config";
import { useTranslation } from "react-i18next";
import Service from "../../auth-module/service/service";
import { useMutation } from "@tanstack/react-query";
import SyMenu from "../../../../../shared/components/sy-menu";
import { HEADER_MENU_CONFIG } from "./app-header-config";
import { useSelector } from "react-redux";
import { getUserName, getuserDetail } from "../../../feature/common-slice/";
import Sybutton from "../../../../../shared/components/sy-button/sy-button";
import { getNavDetails } from "../../../feature/shift-slice";
import { useLocation } from "react-router-dom";
import { theSocket } from "../../../../../shared/socket";
import Avathar from "../../shift-module/components/avathar/avathar";

type Props = {};

const AppHeader = (props: Props) => {
  const { dispatch }: any = useContext(AuthContext);
  const { t } = useTranslation();
  const userDetail = useSelector(getuserDetail);

  const navDetails = useSelector(getNavDetails);


  const UserName = useSelector(getUserName);

  // const [userData, setUserData] = useState<any>(true);

  const { mutate } = useMutation({
    mutationFn: Service.signOut,
    onSuccess() {
      dispatch({ type: "SIGN_OUT", authToken: "" });
      theSocket.emit("logout", getCookie(config.device_id));
    },
  });

  function handleLogOut() {
    mutate({});
  }

  const onClickMenu = (val: any) => {
    if (val.action === "logout") mutate({});
  };

  const handleDropdownItemClick = (e: any) => {
    e.stopPropagation();
    handleLogOut();
  };

  const getFunctionName = (type: any) => {
    switch (type) {
      case "1":
        return "company_admin_shift";
      case "100":
        return "admin_shift";
      case "2":
        return "manager_shift";
      case "3":
        return "employee_shift";
      case "4":
        return "manager_employee_shift";
      case "101":
        return "client_shift";
      case "103":
        return "sta_client_shift";
      default:
        return "";
    }
  };

  const backToSodisys = () => {
    return window.open(`${config.app_url}`, "_blank");
  }

  // if (userDetail?.type == "1") {
  //   setUserData(true);
  // } else {
  //   setUserData(false);
  // }
  const navigate:any = useNavigate();


  // const onMyShiftClick = () => {
  //   // Navigate to the "AnotherPage" route
  //   // navigate('/myshift');
  //   const shiftData:any = true
  //   navigate('/myshift', { state: shiftData });
  // };
  const onMyShiftClick = () => {
    if (location.pathname === '/myshift') {
      // Navigate back to the previous route when on /myshift
      navigate("/dashboard");
    } else {
      // Navigate to /myshift with optional state
      const shiftData = true; // Example state
      navigate('/myshift', { state: shiftData });
    }
  };

  const location = useLocation();

  // Check if the current route is '/myshift'
  const label = location.pathname === '/myshift' ? t('shift_planner_shift') : t('my_shift_shift');


  return (
    <nav className={`${Stylesheet.header_menu} sticky-top  navbar-expand-lg bg-white mx-2 mt-2`}>
      <div className="d-flex p-2 pb-1 pe-3 justify-content-between">
        <div className="d-flex align-items-center">
          <div className={`${Stylesheet.logo_container}`}>
            <img
              className={`${Stylesheet.logo} ms-3 mt-2`}
              src={sodisys_logo}
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-4">
          {/* <div className={`${Stylesheet.timer} me-5`}>
            <span className="material-icons-outlined p-2">timer</span>
          </div> */}
          <Sybutton
            type={"button"}
            className={"primarybutton d-none d-sm-block"}
            size={"sm"}
            label={t("back_to_sodisys_shift")}
            onBtnClick={backToSodisys}
          />

          {/* {(userDetail?.type == "1" && navDetails.clickedData?.selected?.sg_id) && ( */}
          {userDetail?.type === "1" &&
            <Sybutton
              type={"button"}
              className={`primarybutton ${Stylesheet.plan}`}
              size={"sm"}
              value={"plan"}
              // label={userData ? t("my_shifts") : t("shift_planner")}
              label={label}               
              onBtnClick={onMyShiftClick}
            />
          }
          {/* )} */}

          <SyMenu
            onClick={onClickMenu}
            menuItems={HEADER_MENU_CONFIG}
            vertical={"bottom"}
            horizontal={"right"}
            menuBtn={<div
              className={`${Stylesheet.menu} pointer w-100 h-100`}
              id="account-menu"
              aria-haspopup="true"
            >
              <div className="d-flex flexWrap justify-content-between gap-3">
                <Avathar
                  data={userDetail}
                  avatarSize='xl'
                />
                <div>
                  <span className={`${Stylesheet.username} `}>{userDetail?.username}</span>
                  <br />
                  <span className={`${Stylesheet.functionname}  sub-font`}>
                    {userDetail?.function
                      ? userDetail?.function
                      : t(getFunctionName(userDetail?.type))}
                  </span>
                </div>
                <SyIcons
                  iconStyle="outlined"
                  className={`${Stylesheet.ddIcon}`}
                  name={"expand_more"} />
              </div>
            </div>}
          />
        </div>
      </div>
    </nav>

    //     <nav className="navbar navbar-light bg-light">
    //   <div className="container-fluid">
    //     {/* <a className="navbar-brand">Navbar</a> */}
    //     <div></div>
    //     <form className="d-flex">
    //     <SyMenu />
    //       {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"> */}
    //       {/* <button className="btn btn-outline-success" type="submit">Search</button> */}
    //     </form>
    //   </div>
    // </nav>
  );
};

export default AppHeader;
