import { createSlice } from "@reduxjs/toolkit";

interface ChildTypes {
  list: Array<Record<string, any>>;
  selected: Record<string, any>;
}

interface RootState {
  category: ChildTypes;
  subCategory: ChildTypes;
  navLoading: boolean;
}

const initialState: RootState = {
  category: { list: [], selected: {} },
  subCategory: { list: [], selected: {} },
  navLoading: false,
};

const findItem = (list: any, key: string, id: any) => {
  return list.find((item: any) => item?.[key] == id) ?? {};
};

const filterList = (list: any, key: string, id: any) => {
  return list.filter((item: any) => item?.[key] != id);
};

const upsertList = (data: any, key: string, payload: any) => {
  const { list } = data;
  const updateIndex = list.findIndex((item: any) => item?.[key] == payload?.[key]);
  if (updateIndex >= 0) {
    list[updateIndex] = payload;
  } else {
    list.push(payload);
  }
  data.selected = payload;
  return data;
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setCategoryList: (state, { payload }) => {
      state.category.list = payload;
    },
    deleteCategory: (state, { payload }) => {
      state.category.list = filterList(state.category.list, "cgy_id", payload);
      state.category.selected = {};
    },
    setSelectedCategory: (state, { payload }) => {
      state.category.selected = findItem(state.category.list, "cgy_id", payload);
      state.subCategory.selected = {};
    },
    upsertCategory: (state, { payload }) => { // update or insert category
      state.category = upsertList(state.category, "cgy_id", payload);
      state.subCategory.selected = {};
    },
    setSubCategoryList: (state, { payload }) => {
      state.subCategory.list = payload;
    },
    deleteSubCategory: (state, { payload }) => {
      state.subCategory.list = filterList(state.subCategory.list, "scy_id", payload);
      state.subCategory.selected = {};
    },
    setSelectedSubCategory: (state, { payload }) => {
      state.subCategory.selected = findItem(state.subCategory.list, "scy_id", payload);
    },
    upsertSubCategory: (state, { payload }) => {
      state.subCategory = upsertList(state.subCategory, "scy_id", payload);
    },
    resetNavSlice: () => initialState,
  },
});

export const {
  setCategoryList,
  deleteCategory,
  setSelectedCategory,
  setSelectedSubCategory,
  setSubCategoryList,
  deleteSubCategory,
  resetNavSlice,
  upsertCategory,
  upsertSubCategory,
} = navSlice.actions;

export const getNavDetails = (state: any) => state.navReducer;
export const getCategoryList = (state: any) => state.navReducer.category.list;
export const getCategorySelected = (state: any) =>
  state.navReducer.category.selected;
export const getSubCategoryList = (state: any) =>
  state.navReducer.subCategory.list;
export const getSubCategorySelected = (state: any) =>
  state.navReducer.subCategory.selected;

export default navSlice.reducer;
