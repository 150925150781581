import Stylesheet from "./style.module.scss"
type Props = {
  toggleClick : (data:any)=>void;
  value:any
  id?: string;
}

const SyToggleButton = ({toggleClick, value, id = '', ...rest}: Props) => {

  const onChange=(()=>{

  })
  
  return (
    <div className={`${Stylesheet.togglebtn_color} form-check form-switch `}>
      <input
        id={`${id}_toggle`}
        className=" form-check-input pointer border"
        type="checkbox"
        role="switch"
        onClick={toggleClick}
        checked={value}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export default SyToggleButton;
