import SyChip from "../../../../../../shared/components/sy-chip";
import { InputForm } from "../../../../../../shared/components/forms";
import SyIcons from "../../../../../../shared/components/sy-icons";
import styleSheet from "./style.module.scss";
import { useState } from "react";
import { ClickAwayListener } from "@mui/material";
import { Controller } from "react-hook-form";
import { UserListProps } from "./shift-category-interface";
import SyTooltip from "../../../../../../shared/components/sy-tooltip";
import { useTranslation } from "react-i18next";
import Avathar from "../../components/avathar/avathar";

const UserList = (props: UserListProps) => {
  const {
    data,
    index,
    onDeleteClick,
    control,
    name,
    showAllottedTime,
    errors,
  } = props;
  
  const { t } = useTranslation();

  const [chipValue, setChipValue] = useState(data.alloted_time);
  const [view, setView] = useState<"chip" | "field">(
    !chipValue ? "field" : "chip"
  );

  const handleDoubleClick = () => {
    setView("field");
  };

  const handleOnClickAway = () => {
    if (chipValue && !errorMessage) {
      setView("chip");
    }
  };

  const handleDeleteClick = (id: number) => {
    onDeleteClick({ data: data, id });
  };

  const onChangeHours = (data: any, onChange: any) => {
    onChange(data);
    setChipValue(data.target.value);
  };
  const errorMessage: string | undefined =
    errors?.[index]?.alloted_time?.message;

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      document.getElementById(`${index}`)?.blur();
      handleOnClickAway();
    }
  };

  return (
    <div className="d-flex flex-row pt-1">
      <div className="col-6 d-flex align-items-center">
        <Avathar
          id="u_id"
          data={data}
          showName={true}
        />
      </div>
      <div className="col-5">
        {/* {showAllottedTime && ( */}
        <div className={`d-flex justify-content-end ${showAllottedTime ? "" : "d-none"}`}>
          {view === "chip" && (
            <SyTooltip title={t('double_click_to_change_shift')}>
              <div>
                <SyChip
                  color={"#44B81C4D"}
                  label={chipValue}
                  className={styleSheet.chip}
                  onDoubleClickChip={handleDoubleClick}
                />
              </div>
            </SyTooltip>
          )}
          <ClickAwayListener onClickAway={handleOnClickAway}>
            <div
              className={`d-flex  justify-content-end ${view === "chip" && "d-none"
                }`}
              onKeyDown={handleOnKeyDown}
            >
              <Controller
                control={control}
                name={name}
                render={({ field: { onChange, onBlur,value } }) => {
                  const handleChange = (event:any) => {
                    onChange(event.target.value)
                    setChipValue(event.target.value);
                  }
                  return (
                    <div className="w-75 me-1">
                      <InputForm
                        key={data.id}
                        type={"number"}
                        id={`${index}`}
                        defaultValue={data.alloted_time}
                        onInputChange={handleChange}
                        placeholder={t("allotted_time_per_week_shift")}
                        onBlur={onBlur}
                        fieldSize="sm"
                        errorField={!!errorMessage}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </ClickAwayListener>
          <SyTooltip title={errorMessage}>
            <div
              className={`d-flex align-items-center ${styleSheet.warning_icon_div} `}
            >
              <SyIcons
                name="error_outline"
                className={`pointer ${styleSheet.warning_icon} ${!errorMessage && "d-none"
                  } `}
              />
            </div>
          </SyTooltip>
        </div>
        {/* )} */}
      </div>
      <div className="col-1 d-flex align-items-center justify-content-center">
        <SyIcons
          name="close"
          value={index}
          className={styleSheet.delete_icon}
          onClick={handleDeleteClick}
        />
      </div>
    </div>
  );
};

export default UserList;