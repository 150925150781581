//react hooks
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//service
import shiftService from "../../service/shift-service";
//helper
import {
  deleteUidIn,
  getMembers,
  getUniqMemberlist,
} from "./helper";
//redux-slice
import { getNavDetails, setGroupList } from "../../../../feature/shift-slice";
import { upsertSubCategory } from "../../../../feature/shift-slice/nav-slice";
import { getGroupList } from "../../../../feature/shift-slice/scheduler-slice";
//forms
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputForm, SyToggleButton } from "../../../../../../shared/components/forms";
import { groupSchema } from "../../components/schema-validation/schema-validation";
//style
import Stylesheet from "./style.module.scss";
//config
import { type_id_icon } from "./shift-sub-category-config";
import { wildcard } from "../create-shift-category/shift-category-config";
import { setQueryParams } from "../../../../../../shared/utils";
import { getShiftDashBoardParamPayload } from "../../../../../../shared/utils/helper";
//components
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import ShiftColorSelector from "../../components/shift-color-selector";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import UserList from "../create-shift-category/user-list";
import { SyIcons } from "../../../../../../shared/components";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
//styles
import styleSheet from "./style.module.scss";
// interface
import { groupProps, formValues } from "./shift-sub-category-interface";
import { displayOverlayLoading, getCompanyDetails } from "../../../../feature/common-slice";

const SubCategoryManage = (props: groupProps) => {
  const { onPopupClose, mode  , params , onSubmitSucess = () => {} } = props;
  
  const { scy_id: scyId, scy_category: scyCategory } = params || {}
 
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const navDetails = useSelector(getNavDetails);
  const { category } = useSelector(getNavDetails) || {};
  const Themecolor = useSelector(getCompanyDetails);

  // new add member
  const [deletableItem, setDeletableItem] = useState<any>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation({
    mutationFn: shiftService.shiftGroupSave,
    onSuccess(data, variables) {
    
      const scySelect = {
        scy_name: variables.payload.default.scy_name,
        scy_id: variables.id ?? data?.data?.id, //  on edit scenario, id is set from variables otherwise its set from onSuccess response
        scy_category: category?.selected?.cgy_id,
      };
      if (!scyId) {
        setQueryParams(
          getShiftDashBoardParamPayload(
            `cgy=${category?.selected?.cgy_id}/scy=${data?.data?.id}`
          )
        );
      }
      dispatch(upsertSubCategory(scySelect)); // Select newly created subcategory and update list
      SyToast(t(data.data.message), "top-right", "short", "success");
      onSubmitSucess(data?.data?.id);
      onPopupClose();
    },
    onError(err: any) {
      SyToast(
        t(err.response?.data?.message ||
          `something_went_wrong_shift`),
        "top-right",
        "short",
        "error"
      );
      onPopupClose();
    }
  });

  const { mutate: getShiftDetails, isLoading } = useMutation({
    mutationFn: shiftService.getSubCategoryData,
    onSuccess(data) {
      setFormValues(data.data);
    },
  });

  useEffect(() => {
    getMemberList({ searchValue: "", payload: wildcard });
    if(mode === "edit"){
      getShiftDetails(scyId);
    }
  }, []);

  useEffect(() => {
    dispatch(displayOverlayLoading(isLoading ? 1 : 0));
  }, [isLoading]);

  const defaultValues = {
    scy_name: "",
    scy_color: "#1A9DD9",
    scy_category: null,
    members: [],
    scy_time_bound: false
  }

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState
  } = useForm<formValues>({
    mode: "all",
    defaultValues: defaultValues,
    resolver: yupResolver<any>(groupSchema),
  });

  const setFormValues = (val: any) => {
    setValue("scy_name", val.scy_name);
    setValue("scy_color", val.scy_color);
    //new added
    setValue("members", getMembers(val.members));
    setValue("scy_time_bound", val.scy_time_bound)
  };

  //new added
  const {
    data: memberlist,
    mutate: getMemberList,
    isLoading: membersLoading,
  } = useMutation({
    mutationFn: shiftService.getwildCard,
  });

  const {
    fields,
    append: addNewAssignee,
    remove: deleteAssignee,
  } = useFieldArray({
    control: control,
    name: "members",
  });

  const {
    mutate: subCategoryValidation,
  } = useMutation({
    mutationFn: shiftService.getSubCategoryValidation,
    onSuccess(data) {
      deleteAssignee(deletableItem?.id);
    },
    onError(error: any) {
      SyToast(t(error.response.data.message), "top-right", "short", "error");
    },
  });

  const onSubmit = (saveData: any) => {
    //
    saveData.scy_category = scyCategory;
    saveData.type = 2;
    saveData.scy_type = 1;
    saveData.scy_time_bound = saveData.scy_time_bound;
    if (saveData.members && saveData.members.length) {
      saveData.members = deleteUidIn(saveData.members);
    }
    const payload = {
      default: {
        ...saveData,
      },
    };
    saveMutate({ id: mode === 'edit' ? scyId : null, payload });
  };

  //new added
  const handleMemberLabel = (data: any) => data.name;

  const handleParentFilter = (data: string) => {
    getMemberList({ searchValue: data, payload: wildcard });
  };

  const handleChange = (data: any) => {
    addNewAssignee({
      u_id: data.id,
      name: data.name,
      type_id: data.type_id,
      alloted_time: data.alloted_time || ""
    });
  };

  const getIcon = (data: any) => {
    return <SyIcons name={type_id_icon[data.type_id]} className={"me-3"} />;
  };

  const handleDeleteClick = (deletableItem: any) => {
    setDeletableItem(deletableItem);
    setOpenPopup(true);
  };

  const onConfirmDelete = async () => {
    const subCategoryPayload = {
      id: deletableItem?.data?.u_id,
      payload: { scy_id: scyId },
    };
    if (scyId) {
      subCategoryValidation(subCategoryPayload);
    } else {
      deleteAssignee(deletableItem?.id);
    }
    setOpenPopup(false);
  };

  const onCancelClick = () => onPopupClose();

  const handleKeyDown = (e: any) => {
    //*******************************************************************//
    //*****this function is to prevent form submit on pressing enter*****//
    //*******************************************************************//
    e.key === "Enter" && e.preventDefault()
  }

  const sgTimeBound = useMemo(() => {
    return getValues('scy_time_bound');
  }, [watch('scy_time_bound')]);

  return (
    <>
      {openPopup && (
        <SyconfirmPopup
          open={openPopup}
          close={setOpenPopup}
          onConfirmClick={onConfirmDelete}
        />
      )}
      <div className="d-flex mb-3">
        <p className={Stylesheet.chooseCat}>{t(scyId ? 'created_under_shift' : "creating_under_shift")} &nbsp; {category?.selected?.cgy_name}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className={`${formState.errors?.scy_name?.message ? "lh-1" : "pb-3"}`}>
          <div className="mb-3">
            <Controller
              control={control}
              name="scy_name"
              render={({ field: { onChange, value } }) => {
                const handleInputChange = (event: any) => {
                  onChange(event.target.value);
                };
                return (
                  <InputForm
                    placeholder={t("name_shift")}
                    type={"text"}
                    onInputChange={handleInputChange}
                    value={value}
                    error={formState.errors.scy_name?.message}
                    {...{autoFocus:true}}
                  />
                );
              }}
            />
          </div>

          <div className="mt-3 mb-2">
            <Controller
              name="scy_color"
              control={control}
              render={({ field: { onChange, value } }) => {
                return <ShiftColorSelector value={value} onChange={onChange} />;
              }}
            />
          </div>

          {/* future use */}
          {/* <div className={`d-flex pb-3`}>
            <Controller
              name="scy_time_bound"
              control={control}
              render={({ field: { onChange, value } }) => {
                const handleToggleChanged = (event: any) => {
                  onChange(event.target.checked)
                }
                return (
                  <>
                    <SyToggleButton value={value} toggleClick={handleToggleChanged} />
                    <p className="ml-5">{t("alloted_time_shift")}</p>
                  </>
                )
              }}
            />
          </div> */}

          <div>
            <Dropdown
              options={memberlist?.data ? getUniqMemberlist(memberlist.data, fields) : []}
              loading={membersLoading}
              placeholder={t("member_shift")}
              blurOnSelect={true}
              handleFilter={handleParentFilter}
              handleChange={handleChange}
              getoptlabel={handleMemberLabel}
              controlledVal={null}
              getIcon={getIcon}
            />
            <div className="sy_horizontal_scroll pt-1">
              {fields.map((data: any, index: number) => {
                return (
                  <div className={styleSheet.member_list} key={data.id}>
                    <UserList
                      data={data}
                      index={index}
                      showAllottedTime={sgTimeBound}
                      control={control}
                      onDeleteClick={handleDeleteClick}
                      name={`members.${index}.alloted_time`}
                      errors={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-5">
          <Sybutton
            className={`Secondarybutton me-2`}
            label={t("close_shift")}
            type={"reset"}
            onBtnClick={onCancelClick}
            size={"sm"}
          />
          <Sybutton
            className={`primarybutton me-2`}
            label={t("save_shift")}
            type={"submit"}
            size={"sm"}
            Loadings={saveLoading}
          />
        </div>
      </form>
    </>
  );
};

export default SubCategoryManage;
