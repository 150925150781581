import { memo, ReactElement } from "react";

type Props = {
  data: any;
  onClick: (data: any, type: string) => void;
  children: ReactElement;
};

const SlotWrapper = ({ children, data, onClick }: Props) => {
  const handleClick = () => {
    onClick(data, "empty");
  };

  return (
    <span className="w-100 h-100 d-block" onClick={handleClick}>
      {children}
    </span>
  );
};

export default memo(SlotWrapper);
