import React, {
  useState,
  useEffect,
  useRef,
  memo,
  useCallback,
  useMemo,
} from "react";
import "./sy-calendar.scss";
import HeaderSheduler from "../sy-sheduler/components/header-sheduler";
import {
  calculateCalendarWidth,
  calculateEventPositions,
  getEventsForDate,
  initSyncScroll,
  isDateSelectable,
  splitEventsByDay,
} from "./helper";
import { DayProps, SyCalendarProps } from "./sy-calendar-model";
import RenderDayList from "./components/renderDayList";
import RenderTimeSlot from "./components/renderTimeSlot";
import {
  generateTimes,
  generateWeekDates,
  getDateLocaleString,
} from "../../utils/dayjs";

const SyCalendar = (props: SyCalendarProps) => {
  const {
    data,
    defaultDate,
    calenderView = 1,
    onHeaderBtnClick,
    onChangeDate,
    disabled = false,
    isPreviousDisabled = false,
    isFutureDisabled = false,
    headerButtonConfig,
    onEventClick = () => {},
    showListView= false
  } = props;

  // State
  const [date, setDate] = useState(defaultDate ? defaultDate : new Date());
  const [view, setView] = useState<1 | 2>(calenderView);
  const [eventWidth, setEventWidth] = useState(view === 1 ? 124 : 39);
  const [dayWidth, setDayWidth] = useState(view === 1 ? 100 : 39);
  const [dates, setDates] = useState<any>([]);
  const [times, setTimes] = useState([]);
  const hourHeight = 70; // Height in pixels for one hour
  // const [hoveredEventId, setHoveredEventId] = useState<any>({});

  //Ref
  const div1Ref: any = useRef(null);
  const div2Ref: any = useRef(null);

  /**
   * Calculates and sets the width of the calendar days based on the provided container reference,
   * the current calendar view, and the set of dates to display.
   *
   * @param {any} calendarContainerRef - The reference to the DOM element (container) where the calendar is rendered.
   * @param {string} calendarView - The current view/layout of the calendar (e.g. "1", "2").It Returns
   * @param {Array} calendarDates - An array of date objects that represent the dates being displayed in the calendar.
   *
   * @returns {void} - This function does not return any value. It updates the calendar's day width based on the calculated result.
   */
  const setCalendarDayWidth = (
    calendarContainerRef: any,
    calendarView: 1 | 2,
    calendarDates: []
  ) => {
    const calculatedWidth = calculateCalendarWidth(
      calendarContainerRef,
      calendarView,
      calendarDates
    );
    if (calculatedWidth) {
      setDayWidth(calculatedWidth);
      setEventWidth(calculatedWidth)
    }
  };

  // Flags to prevent infinite loop in sync scroll
  const isSyncingDiv1Scroll = useRef(false);
  const isSyncingDiv2Scroll = useRef(false);

  // After component mounts, initialize sync scroll
  useEffect(() => {
    const generatedTimes: any = generateTimes("time");
    setTimes(generatedTimes);
    initSyncScroll(
      div1Ref,
      div2Ref,
      isSyncingDiv1Scroll,
      isSyncingDiv2Scroll,
      // onTooltipClose
    );
    setCalendarDayWidth(div1Ref, view, dates);
  }, []);

  // Assign events to the corresponding dates
  const assignEventsToDates = useCallback(
    (events: any, dates: any) => {
      if (!events) return;
      dates.forEach((day: any) => {
        day.events = getEventsForDate(events, day.date);
        day.width = calculateEventPositions(
          day.events,
          dayWidth,
          hourHeight,
          eventWidth,
          showListView
        );
      });
      setDates([...dates]);
      setCalendarDayWidth(div1Ref, view, dates);
    },
    [dayWidth, hourHeight, view,showListView]
  );

  // When data and view change
  useMemo(() => {
    const splitData: any = splitEventsByDay(data || []);
    const generatedDates: any = generateWeekDates(view, date);
    setDates(generatedDates);
    assignEventsToDates(splitData, generatedDates);
  }, [data, view,showListView]);

  const onHeaderButtonClick = useCallback(
    (val: any, type: string) => {
      if (type == "headerBtn") {
        if (val == 1 || (val == 2 && val !== view)) {
          setView(val);
          const generatedDates: any = generateWeekDates(val, date);
          setCalendarDayWidth(div1Ref, val, generatedDates);
        }
        onHeaderBtnClick(val);
      }
      if (type === "date") {
        setCalendarDayWidth(div1Ref, view, dates);
        setDate(val);
        onChangeDate(val);
      }
    },
    [div1Ref, view, dates,showListView]
  );

  const onClickSlot = useCallback(
    (time: number, day?: DayProps) => {
      if (!day) return;
      if (
        !disabled &&
        isDateSelectable(day, isPreviousDisabled, isFutureDisabled)
      ) {
        const dayTime = {
          time: time,
          date: getDateLocaleString(day.date, "year"),
        };
        onEventClick(dayTime, "add");
      }
      // setHoveredEventId({});
    },
    [disabled, isPreviousDisabled, isFutureDisabled]
  );

  const handleEventClick = useCallback(
    (event: object) => {
      if (!disabled) {
        onEventClick(event, "edit");
      }
      // setHoveredEventId({});
    },
    [disabled]
  );

  // const onTooltipClose = useCallback(() => {
  //   setHoveredEventId(null);
  // }, [hoveredEventId]);

  // const onTooltipOpen = useCallback((event: object) => {
  //   setHoveredEventId(event);
  // }, []);

  /**
   * Component that renders the list of time slots in the calendar.
   * Each time slot is displayed in a vertical column to allow scheduling events at specific times.
   *
   * @param {Object[]} times - An array of time objects to be rendered as time slots.
   * @param {number} hourHeight - The height of each time slot, representing the vertical space for each hour.
   */
  const TimeColumnList = memo(
    ({ times, hourHeight }: { times: any[]; hourHeight: number }) => {
      const memoizedTimeSlots = useMemo(() => {
        return times.map((time: any, index: number) => (
          <RenderTimeSlot
            key={index}
            index={index}
            hourHeight={hourHeight}
            time={time}
          />
        ));
      }, [times, hourHeight]);

      return <div className="time-slots">{memoizedTimeSlots}</div>;
    }
  );

  /**
   * Component that renders the list of days in the calendar.
   * It generates a column view for each day, displaying events and allowing interactions like hovering or clicking.
   */
  const DayColumnList = memo(() => {
    const memoizedDayList = useMemo(() => {
      return dates.map((day: DayProps, index: number) => (
        <RenderDayList
          key={index}
          day={day}
          times={times}
          index={index}
          calendarView={view}
          showListView={showListView}
          // hoveredEventDetails={hoveredEventId}
          onClickSlot={onClickSlot}
          onEventClick={handleEventClick}
          // onTooltipOpen={onTooltipOpen}
          // onTooltipClose={onTooltipClose}
        />
      ));
    }, [dates, times, view, ]);
    return <>{memoizedDayList}</>;
  });
  return (
    <div className="calendar-container calender-theme-color rounded">
      <HeaderSheduler
        headerButtons={headerButtonConfig}
        onButtonClick={onHeaderButtonClick}
        shedulerChange={view}
      />
      {/* Time and Events */}
      <div className="calendar-grid">
        {/* Time Column */}
        <div
          className="time-column hide-scroll calender-theme-color"
          ref={div2Ref}
          id="div2"
          style={{ display: showListView ? 'none' : 'block' }}
        >
          <div
            className="day-header calender-theme-color"
            style={{
              height: `${hourHeight}px`,
            }}
          ></div>
          <TimeColumnList times={times} hourHeight={hourHeight} />
        </div>

        {/* Days Columns */}
        <div
          className={showListView ? "days-columns sy_vertical_scroll sy_horizontal_scroll" : "days-columns sy_vertical_scroll sy_vertical_scroll sy_horizontal_scroll"}
          ref={div1Ref}
          id="div1"
        >
          <DayColumnList />
        </div>
      </div>
    </div>
  );
};

export default SyCalendar;
