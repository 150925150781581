export const SHEDULER_VIEW_CONFIG: any = {
  week: {
    value: 1,
    label: "week_shift",
  },
  month: {
    value: 2,
    label: "month_shift",
  },
  day: {
    value: 3,
    label: "day",
  },
};
