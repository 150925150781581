import React from "react";
import Collapse from "@mui/material/Collapse";

type Props = {
  children?: React.ReactNode;
  isOpen: boolean;
};

const SyCollapse = (props: Props) => {
  const { children = "", isOpen = false } = props;
  return (
    <div>
      <Collapse in={isOpen}>{children}</Collapse>
    </div>
  );
};

export default SyCollapse;
