/**
 * @file socket.js
 * @description This file handles the initialization and management of the WebSocket connection using socket.io-client and This is common, so we can use all over the project as theSocket.on("name of the function", ()=> function ).
*/

import { Manager } from "socket.io-client";
import { getCookie } from "../utils";
import config from "../../environments/env-config";
import { doLogOut } from "../cmn/cmn-service";

/**
 * Retrieves the device ID from cookies.
 * @constant {string}
*/
const deviceId = getCookie(config.device_id) || ""; 

/**
 * Initializes a Socket.IO Manager instance.
 * @constant {Manager}
*/
const manager = new Manager(config.socket_api_url, {
  autoConnect: false,
});

/**
 * Socket instance for the application.
 * @constant {Socket}
*/
export const theSocket = manager.socket("/");

/**
 * Initializes and connects the socket.
 * - Connects if not already connected.
 * - Emits the device ID upon connection.
 * - Listens for the `logout` event.
*/
export const initSocket = () => {
  if (!theSocket.connected) {
    theSocket.connect();
  }

  theSocket.on("connect", () => {
    theSocket.emit("device", deviceId);
  });

  theSocket.on("logout", () => {
    doLogOut();
  });

};

/**
 * Removes event listeners.
*/
export const closeSocket = () => {
  if (theSocket.connected) {
    theSocket.off("connect");
    theSocket.off("logout");
  }
};
