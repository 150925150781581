import { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Stylesheet from "./style.module.scss";
import shiftService from "../../service/shift-service";
import { shedulerButton, wildcard } from "./config";
import { setShiftAccessList } from "../../../../feature/shift-slice/scheduler-slice";
import { getuserDetail } from "../../../../feature/common-slice";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import { SyLoadingIcon } from "../../../../../../shared/components";
import { generatePayload, getPayloadDate } from "./helper";
import ShiftScheduler from "../../components/shift-scheduler";
import Popup from "../../../../../../shared/components/sy-model";
import ShiftPopup from "../../popup/new-shift-popup";
import SyCalendar from "../../../../../../shared/components/sy-calendar";
import { createAbortSignal } from "../../../../../../shared/utils/axios";
import { useSearchParams } from "react-router-dom";
import { setQueryParams } from "../../../../../../shared/utils";
import Avathar from "../../components/avathar/avathar";

const signal = createAbortSignal();

const MyShiftScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams(); // Get query params
    const id = searchParams.get("id");

    //-selectors--//
    const userDetail = useSelector(getuserDetail);
    const {user_id: userId, master_type: masterType, type: userType, username: name} = userDetail;
    //--states--//
    // const [weekSheduler, setWeekSheduler] = useState<any>([]);
    const [schedulerDate, setSchedulerDate] = useState(new Date())
    const [schedulerType, setSchedulerType] = useState(1);
    const [memberDetails, setMemberDetails] = useState<Record<string, any>>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [expand , setExpand] = useState<boolean>(true);
    const [shiftId, setShiftId] = useState(false);
    const [dropdownDefaultValue, setDropdownDefaultValue] = useState({ id: userId, name: name })
   
    useEffect(() => {
        getMembers({searchValue: '', payload: wildcard})
        const payload = generatePayload(schedulerDate, schedulerType, id ? id : userId, masterType);
        getShiftData({payload, signal: signal()});
    }, []);

    const setDefaultDropDownValue = (value: any) => {
      if (id) {
        const [filteredVal] = value?.filter((item: any) => {
          return item?.id == id;
        });
        setDropdownDefaultValue({
          id: filteredVal?.id,
          name: filteredVal?.name,
        });
      }
    };

    //exportlist
    const { mutate: getExportList } = useMutation({
        mutationFn: shiftService.getExportList,
        onSuccess(data: any) {
            window.location.href = data.data.file;
        },
    });

    const { data: membersData, mutate: getMembers, isLoading: membersLoading } = useMutation({
        mutationFn: shiftService.getwildCard,
        onSuccess:(data)=>{
            setDefaultDropDownValue(data?.data)
        }
    });

    const { data: shiftData, mutate: getShiftData, isLoading: myShiftLoading } = useMutation({
        mutationFn: shiftService.getShiftsForMember,
        onSuccess(data: any) {
            dispatch(setShiftAccessList(data.data));
            setIsLoading(false);
        },
    });

    const onClickExport = () => {

        const payload = {
            date: getPayloadDate(schedulerDate, schedulerType),
            type: schedulerType === 1 ? "week" : "month",
            user_id: memberDetails?.id || userId,
        };

        getExportList(payload);
    };

    const onHeaderBtnClick = (val: any) => {
        if (val == 1 || val == 2) {
            setSchedulerType(val);
            const payload = generatePayload(schedulerDate, val, id ? id : userId, masterType, memberDetails);
            getShiftData({payload, signal: signal()});
        }
        if ( val === 'expand' ) {
            setExpand(true);
        }
        if ( val === 'back' ) {
            setExpand(false);
        }
    };

    const onChangeMember = (val: any) => {
        setDropdownDefaultValue({ id: val?.id, name: val?.name });
        setQueryParams({url:`id=${val?.id}`})
        setMemberDetails(val);
        setIsLoading(true);
        const payload = generatePayload(schedulerDate, schedulerType, userId, masterType, val);
        getShiftData({payload, signal: signal()});
    }

    const handleDateChange = (val: any) => {
        setSchedulerDate(val);
        const payload = generatePayload(val, schedulerType, id ? id : userId, masterType, memberDetails);
        getShiftData({payload, signal: signal()});
    }

    const onSlotClick = (val: any, action: "add" | "edit") => {

        if (action === 'edit') {
            setShiftId(val?.val?.ss_id);
        }
    }

    const onShiftPopupClose = () => {
        setShiftId(false);
    }
    
    return (
        <>
            {!!shiftId && (
                <Popup
                open={!!shiftId}
                close={onShiftPopupClose}
                model_type={"responsive"}
                maxWidth={"lg"}
                title={`edit_shift`}
                >
                    <ShiftPopup
                        mode="edit"
                        onPopupClose={onShiftPopupClose}
                        schedulerView={schedulerType == 1 ? 'week' : 'month'}
                        params={{shift_id: shiftId}}
                    />
                </Popup>
            )}
            <div className={`${Stylesheet.container_fluid} container-fluid`}>
                <div className={`${Stylesheet.container} mb-2`}>
                    <div className=" text-right d-flex justify-content-between align-items-center mx-2 my-3 gap-3">                        
                        {userType !== "1" ? (
                            <div className={`cursor-pointer d-flex align-items-center gap-3 ${Stylesheet.cursor_pointer}`}>
                                 <Avathar data={userDetail} avatarSize='xl'/>
                                 <span className={`${Stylesheet.userName}`}>{name}</span>
                            </div>
                            ) : (
                            <div className={`${Stylesheet.drop_down}`}>
                                <Dropdown
                                options={membersData?.data || []}
                                placeholder={t("user_shift")}
                                handleChange={onChangeMember}
                                getoptlabel={(val) => val?.name}
                                controlledVal={dropdownDefaultValue}
                                clearIcon={true}
                                loading={membersLoading}
                                />
                            </div>
                        )}
                        {/* { userType == "1" &&
                        <span
                        className={`material-symbols-outlined mt-3 mb-3 cursor-pointer ${Stylesheet.cursor_pointer}`}
                        onClick={onClickExport}
                        >
                            {'print'}
                        </span>
                        } */}
                    </div>
                    <div className={`d-flex g-0 px-2 pb-2  flex-wrap`}>
                        {/* {isLoading ? (
                            <div className={`col-md-8 col-sm-7 col-lg-9 col-12 ${Stylesheet.loaderCard}`}>
                                <SyLoadingIcon size="sm" />
                            </div>
                        ) : */}
                            <div className={`col-12 pb-3 border-0`} >
                                <div className="info_content">
                                    {/* <ShiftScheduler
                                        data={shiftData?.data || []}
                                        view="hours"
                                        headerButton={shedulerButton(expand)}
                                        onHeaderBtnClick={onHeaderBtnClick}
                                        sgLoading={myShiftLoading}
                                        expand={expand}
                                        schedulerView={schedulerType}
                                        onChangeDate={handleDateChange}
                                        onSchedulerClick={onSlotClick}
                                    /> */}
                                     <SyCalendar
                                      data={shiftData?.data}
                                      calenderView={1}
                                      onChangeDate={handleDateChange}
                                      onHeaderBtnClick={onHeaderBtnClick}
                                      disabled={false}
                                      headerButtonConfig={shedulerButton(expand)}
                                      showListView={expand}
                                       />
                                </div>
                            </div>
                        {/* } */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyShiftScreen;