import { memo } from "react";
import { RenderDaySlotProps } from "../sy-calendar-model";
import { getDateLocaleString } from "../../../utils/dayjs";
/**
 * @param {number} props.hourHeight - The height (in pixels) of the time slot for this date.
 * @param {Object} props.day - An object representing the day, which includes a `date` (moment.js object) and `width`.
 * @param {number} props.calendarView - A flag to determine which view to display:
 */
const RenderDaySlot = (props: RenderDaySlotProps) => {
  const { hourHeight = 70, day, calendarView = 1 } = props;

  return (
    <div
      className="day-header p-1 calender-theme-color"
      style={{
        height: `${hourHeight}px`,
        position: "sticky",
        top: 0,
        width: `${day.width - 1}px`,
      }}
    >
      {calendarView === 1 ? (
        <>
          <div className="day-name">{getDateLocaleString(day.date,"fullWeekDay")}</div>
          <div className="day-date">{getDateLocaleString(day.date,"date")}</div>
        </>
      ) : (
        <>
          <div className="day-name">{getDateLocaleString(day.date,'dayOfMonth')}</div>
          <div className="day-date">{getDateLocaleString(day.date,"setdate")}</div>
        </>
      )}
    </div>
  );
};
export default memo(RenderDaySlot);
