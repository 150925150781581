import { useEffect, useState } from "react";
import SyLoadingIcon from "../sy-loading-icon";
import StyleSheet from "./style.module.scss";
import SyIcons from "../sy-icons";
import { useTranslation } from "react-i18next";
import SyTooltip from "../sy-tooltip";

interface ButtonData {
  label?: any;
  type: "button" | "submit" | "reset";
  className?: string;
  size: string;
  value?: any;
  disabled?: boolean;
  Loadings?: boolean;
  id?: any;
  onBtnClick?: (val: string) => void;
  icontype?: string;
  iconclass?: any;
  loadingCursor?: boolean;
  toolTipTitle?:string
}
const Sybutton: React.FC<ButtonData> = ({
  label,
  type,
  className = '',
  disabled,
  size = "sm",
  value = "",
  Loadings,
  id,
  onBtnClick,
  icontype,
  iconclass,
  loadingCursor= false,
  toolTipTitle
}) => {
  const { t } = useTranslation();
  const [style, setStyle] = useState("");
  const onClick = () => {
    onBtnClick ? onBtnClick(value) : void null;
  };

  useEffect(() => {
    setStyle(className);
  }, [className]);

  const Loading = (props: any) => {
    const { label } = props;
    return (
      <>
        <SyLoadingIcon color={`${StyleSheet.loading} me-1`} size={"sm"} />
        <span className={``}>{t(label)}</span>
      </>
    );
  };

  const WithIcon = (props: any) => {
    const { label, icon } = props;
    return (
      <>
        <SyIcons
        name={icon}
        className={`material-icons pointer ${iconclass} ${StyleSheet.btn_icon} btn-icon`}
        onClick={onClick}
      />
        <span className={`${StyleSheet.label} ellipsis mt-1`}>{t(label)}</span>
      </>
    );
  };

  return (
    <SyTooltip title={toolTipTitle}>
    <span className={`${loadingCursor ? "loading-cursor" : ""}`}>
      <button
        type={type}
        id={id}
        className={`${className} text-nowrap btn btn-${size} `}
        value={value}
        onClick={onClick}
        disabled={disabled || Loadings}
      >
        {Loadings ? <Loading label={value} /> :
          icontype ? <WithIcon label={value} icon={icontype} /> :
            label}
      </button>
    </span>
    </SyTooltip>
  );
};

export default Sybutton;
